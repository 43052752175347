import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--grisClaro);

  // Max Width Container
  section {
    width: 100%;
    max-width: 1270px;
    padding: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Tag Container, Title Container, Text Container, Subtitle Container & Second Text Container
  .tag-container,
  .title-container,
  .text-container,
  .subtitle-container,
  .second-text-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    // Tag
    div {
      width: auto;
      padding: 8px 16px 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      background-color: #e5fff8;
      border-radius: 76px;
    }

    // Tag Text
    p {
      width: auto;
      margin: 0px 0px 0px 0px;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      color: #50c7a3;
      line-height: 154.7%;
    }
  }

  // Title Container
  .title-container {
    margin: 32px 0px 0px 0px;

    // Title
    h3 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      letter-spacing: -0.015em;
      line-height: 143.5%;

      // Span
      span {
        color: var(--azul100);
      }
    }
  }

  // Text Container
  .text-container {
    margin: 32px 0px 0px 0px;

    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 203%;
      letter-spacing: -0.015em;
      color: var(--negro);
    }
  }

  // Tabs Container
  .tabs-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Tabs
  #tabs {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
  }

  // Tab
  #tabs div {
    width: auto;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Tab Botón
  #tabs a {
    width: auto;
    height: 40px !important;
    margin: 0px 8px 0px 8px;
    padding: 0px 24px 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 6px !important;
    border: 1px solid var(--grisClaro);
    background-color: #f4f7fd;
    font-size: 13px;
    color: var(--azul60);
    transition: 0.15s all;
  }

  .tab-activa {
    background-color: var(--azul60) !important;
    color: var(--blanco) !important;
  }

  // Tabs Botón Icono
  #tabs a span {
    margin: 3px 8px 0px 0px;
    font-size: 20px;
  }

  // CSS Transition Group
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: all 250ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: all 250ms;
  }

  // Switch Container
  .switch-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Data Container
  #data-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Canal Container
  .canal {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Canal Botones
  .canal a {
    margin: 2px 8px 0px 8px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--verde50);
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 192.7%;
    opacity: 1;
    cursor: pointer;
    transition: 0.2s;
  }

  // Canal Botones Ícono
  .canal a span {
    margin: 6px 6px 0px 0px;
    color: var(--verde50);
    font-size: 16px;
    opacity: 1;
    transition: 0.2s;
  }

  // Canal Botones Hover
  .canal a:hover {
    opacity: 0.7;
    span {
      opacity: 0.8;
    }
  }

  // Botón Mail
  #boton-mail {
    color: var(--naranja100);
  }

  // Botón Mail Ícono
  #boton-mail span {
    margin: 8px 6px 0px 0px;
    font-size: 19px;
    color: var(--naranja100);
  }

  // Canal Line
  .canal p {
    margin: 0px 0px 0px 0px;
    color: #efefef;
    font-weight: light;
    font-size: 24px;
    text-align: center;
  }

  // Subtitle Container
  .subtitle-container {
    margin: 32px 0px 0px 0px;

    p {
      margin: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 203%;
      text-align: center;
      letter-spacing: -0.015em;
      color: #7794e5;
    }
  }

  // Second Text Container
  .second-text-container {
    margin: 8px 0px 0px 0px;

    p {
      margin: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 192.7%;
      text-align: center;
      letter-spacing: -0.015em;
      color: #747474;
    }
  }

  // Botón Contacto Container
  .boton-contacto-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    // Botón
    a {
      margin: 0px 0px 0px 0px;
      padding: 12px 24px 12px 24px;
      border-radius: 6px;
      color: var(--azul100);
      background-color: #f4f7fd;
      cursor: pointer !important;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    // Botón Hover
    a:hover {
      background-color: #dde8ff;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    section {
      max-width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    section {
      max-width: 480px;
    }

      // Tabs Container
  .tabs-container {
    margin: 24px 0px 0px 0px;
  }

    // Tab Botón
  #tabs a {
    width: 200.66px;
    margin: 16px 0px 0px 0px;
  }

    // Canal Container
    .canal {
      flex-wrap: wrap;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    section {
      max-width: 350px;
    }

    // Text Container
    .text-container p {
      width: 80%;
    }

  // Switch Container
  .switch-container {
    margin: 32px 0px 0px 0px;
  }

      // Canal Botones
  .canal a {
    width: 100%;
    margin: 8px 0px 0px 0px;
  }


      // Canal Line
  .canal p {
    display: none;
  }

      // Second Text Container
  .second-text-container p {
    width: 80%;
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Max Width Container
    section {
      max-width: 85%;
    }
  }
`;

export default Container;

import React, { useState } from "react";
import Confirmacion from "../Confirmacion";
import Contrata from "../Contrata";
import Elegi from "../Elegi";
import Footer from "../../footer";
import { CompletaInfo } from "../CompletaInfoAuto";

export default function StepsSwitch({ actualizarVehiculo }) {
  const [step, setStep] = useState(1);
  const [plan_selected, setPlan_selected] = useState("");
  const [id_tablaAutos, setId_tablaAutos] = useState("");
  const [anioAuto, setAnioAuto] = useState("")

  function prevStep() {
    setStep(step - 1);
  }

  // Actualiza el ID contrata y el ID cotización que llegan desde el back durante el Step 1
  function updateIds(idTablaAutos) {
    setId_tablaAutos(idTablaAutos);
  }

  // Actualiza el plan seleccionado que se muestra en el Step 2 cuando se hace click en "contrata ahora" durante el Step 1
  function planSelected(plan) {
    setPlan_selected(plan);
  }

  function nextStep() {

        setStep(step + 2);

  }


  switch (step) {
    case 1:
      return (
        <>
        <CompletaInfo
          nextStep={nextStep}
        />

        <Footer marcas=""></Footer>
        </>
      );

    case 2:
      return (
        <>
          <Contrata
            prevStep={prevStep}
            nextStep={nextStep}
            planSelected={plan_selected}
            idTablaAutos={id_tablaAutos}
          />
          <Footer marcas=""></Footer>
        </>
      );

    case 3:
      return (
        <>
        <Confirmacion
          prevStep={prevStep}
          nextStep={nextStep}
          planSelected={plan_selected}
          anioAuto={anioAuto}
        />
        <Footer marcas="active"></Footer>
        </>
      );
    default:
  }
}

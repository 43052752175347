import React, { Component } from "react";
import Container from "./styled";
import Desplegable from "./desplegable";
import Animacion from "./animacion";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

export default class Referidos extends Component {
  // Estado que determina si esta abierta o no el desplegable
  state = {
    isActiveUno: false,
    isActiveDos: false,
  };

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de los desplegables
  handleToggleUno = () => {
    this.setState({
      isActiveUno: !this.state.isActiveUno,
      isActiveDos: false,
    });
  };

  handleToggleDos = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: !this.state.isActiveDos,
    });
  };

  render() {
    return (
      <Container>
        <div id="max-width-container">
          {/* Tag Container */}
          <Fade delay={100} duration={800} className="tag-container">
            {/* Tag */}
            <div>
              <p>Referidos</p>
            </div>
          </Fade>
          {/* Titulo */}
          <Fade delay={100} duration={800}>
            <h3>
              ¡Aprovechá nuestro <span>programa de referidos</span>!
            </h3>
          </Fade>
          <Fade delay={100} duration={800} triggerOnce={true}>
            <p id="texto-titulo">
              ¡Invitá a tus amigos y ganá importantes premios!
            </p>
          </Fade>
          {/* Información Container */}
          <div id="informacion-referidos-container">
            <div id="imagen-container">
              <Fade
                delay={100}
                duration={600}
              >
                <img src="/images/home/referidos/referidos.webp"></img>
              </Fade>
            </div>
            <div id="texto-container">
              <Fade
                delay={100}
                duration={600}
                className={
                  this.state.isActiveDos == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="¿Qué puedo ganar?"
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  ¡Podes referir personas y ganar hasta <span>$4000</span> por cada referido! Sin ningún tope de ganancias.
                  Además, tu referido obtiene hasta un <span>30% </span> de descuento en el seguro que contrate.
                </Desplegable>
                <Fade delay={100} duration={800}>
                  <Link to="/referidos" id="referir-boton">
                    Quiero referir
                  </Link>
                </Fade>
              </Fade>
            </div>
            
          </div>
          
        </div>
      </Container>
    );
  }
}

import styled, { css } from "styled-components";
import { breakpoints } from "../../../../constants/media";

export const Container = styled.div`
  width: 277px;
  height: 170px;
  margin: 26px 28.5px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background: linear-gradient(25deg, #fbfbfb, #e8e9e5);
  // box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  font-family: "Consolas", "Courier", monospace !important;
  background: ${(props) => {
    switch (props.brand) {
      case "visa":
        // return "linear-gradient(25deg, #0f509e, #1399cd)";
        return "linear-gradient(-45deg, #0f0c29, #302b63, #24243e)";
      case "americanexpress":
        return "linear-gradient(25deg, #0f509e, #11304e)";
      case "mastercard":
        return "linear-gradient(25deg, #fbfbfb, #e8e9e5)";
      case "maestro":
        return "linear-gradient(25deg, #fbfbfb, #e8e9e5)";
      case "naranja":
        return "linear-gradient(25deg, #fe5000, #ff8b56)";
      case "dinersclub":
      case "cabal":
      case "discover":
      case "unionpay":
      case "jcb":
      case "elo":
      case "hipercard":
      case "mir":
      case "hiper":
        return "linear-gradient(25deg, #fbfbfb, #e8e9e5)";
    }
  }};
  user-select: none !important;
  position: relative;
  transition: 0.6s all;

  // logo y Chip
  #logos-tarjeta {
    width: 100%;
    max-height: 32.47px;
    margin: 15px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    // Chip
    .chip {
      width: 40px;
    }

    // Logo Marca
    .logo-marca {
      width: ${(props) => {
        switch (props.brand) {
          case "visa":
            return "60px";
          case "americanexpress":
            return "40px";
          case "mastercard":
            return "60px";
          case "maestro":
            return "50px";
          case "naranja":
            return "10px";
          case "dinersclub":
            return "70px";
          case "cabal":
            return "10px";
          case "discover":
            return "70px";
          case "unionpay":
            return "30px";
          case "jcb":
            return "50px";
          case "elo":
            return "50px";
          case "hipercard":
            return "50px";
          case "mir":
            return "70px";
          case "hiper":
            return "30px";
        }
      }};
      transition: 0.2s all;
      animation-name: logo-animation;
      animation-duration: 0.2s;
      animation-iteration-count: initial;
      animation-timing-function: ease-in-out;
    }

    @keyframes logo-animation {
      from {
        transform: scale(0.8);
        opacity: 0%;
      }
      50% {
        opacity: 50%;
      }
      to {
        transform: scale(1);
        opacity: 100%;
      }
    }
  }

  // Datos Tarjeta
  .datos-tarjeta {
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  
  .numero-tarjeta {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    font-family: "Consolas", "Courier", monospace !important;
    color: #333;
    color: ${(props) => {
      switch (props.brand) {
        case "visa":
        case "americanexpress":
        case "naranja":
          return "var(--lightgray2)";
        case "mastercard":
        case "maestro":
        case "cabal":
        case "discover":
        case "unionpay":
        case "jcb":
        case "elo":
        case "hipercard":
        case "mir":
        case "hiper":
          return "#999";
      }
    }};
  }

  // Datos Tarjeta Nombre y Vencimiento
  .datos-tarjeta-nombre-vencimiento {
    width: 100%;
    margin: 22px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;

    // Nombre y Vencimiento
    .nombre-tarjeta,
    .vencimiento-tarjeta {
      width: 70%;
      margin: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
      align-content: flex-end;

      p {
        width: 100%;
        max-width: 130px;
        margin: 0px 0px 0px 0px;
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #333;
        color: ${(props) => {
          switch (props.brand) {
            case "visa":
            case "americanexpress":
            case "naranja":
              return "var(--lightgray2)";
            case "mastercard":
            case "maestro":
            case "cabal":
            case "discover":
            case "unionpay":
            case "jcb":
            case "elo":
            case "hipercard":
            case "mir":
            case "hiper":
              return "#999";
          }
        }};
      }

      #texto {
        margin: 0px 0px 5px 0px;
        font-size: 11px;
        opacity: 0.6;
      }
    }

    .vencimiento-tarjeta {
      width: 30%;
      justify-content: flex-end;

      p {
        text-align: right;
      }
    }
  }

  .numero-tarjeta,
  .nombre-tarjeta,
  .vencimiento-tarjeta {
    transition: 0.2s all;
  }

  #active {
    color: #999;
    color: ${(props) => {
      switch (props.brand) {
        case "visa":
        case "americanexpress":
        case "naranja":
          return "var(--lightgray2)";
        case "mastercard":
        case "maestro":
        case "cabal":
        case "discover":
        case "unionpay":
        case "jcb":
        case "elo":
        case "hipercard":
        case "mir":
        case "hiper":
          return "#999";
      }
    }};
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    width: 238.5px;
    height: 160px;
    margin: 26px 16px 0px 0px;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: 277px;
    height: 170px;
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    display: none;
  }
`;

import * as React from "react";
import Container from "./styled";

export default function Marcador({
  pasoUno,
  pasoDos,
  pasoTres,
  pasoTresTexto,
  pasosTerminados,
  titulo,
}) {

  return (
    <Container>
      {/* Titulo */}
      <h3>{titulo}</h3>
      {/* <h3>Cotizaste tu seguro con éxito!</h3> */}
      {/* Pasos Container */}
      <div id="pasos-container">
        {/* Paso 1 */}
        <div
          className="paso"
          id={
            pasoUno == "hecho"
              ? "hecho"
              : pasosTerminados == true
              ? "terminado"
              : ""
          }
        >
          <span></span>
        </div>
        {/* Paso 2 */}
        <div
          className="paso"
          id={
            pasoDos == "hecho"
              ? "hecho"
              : pasosTerminados == true
              ? "terminado"
              : "haciendo"
          }
        >
          <span></span>
        </div>
        {/* Paso 3 */}
        <div
          className="paso"
          id={
            pasoTres == "haciendo"
              ? "haciendo"
              : pasosTerminados == true
              ? "terminado"
              : ""
          }
        >
          <span></span>
        </div>
      </div>
      {/* Texto Container */}
      <div id="texto-container">
        {/* Paso 1 */}
        <p className="texto">Datos personales</p>
        {/* Paso 2 */}
        <p className="texto">Datos Vehículo</p>
        {/* Paso 3 */}
        <p className="texto">Obtener cotización</p>
      </div>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Container from "./styled";
import { BiError } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import { FaCarSide } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import url from "../../utils/url";
import axios from "axios";
import AnimacionLoading from "./animacion-loading";

export default function Cotizador({
  subtitle,
  landingVehiculo,
  actualizarVehiculo,
  isReferidosHeaderOpen,
  marketing_url,
}) {
  // Estado para el loading
  const [loading, setLoading] = useState(false);
  // Valores de los inputs
  const [inputValues, setInputValues] = useState({
    nombre: localStorage.getItem("nombre-seguroautos")
      ? localStorage.getItem("nombre-seguroautos")
      : "",
    email: localStorage.getItem("email-seguroautos")
      ? localStorage.getItem("email-seguroautos")
      : "",
    codigoArea: localStorage.getItem("codigoArea-seguroautos")
      ? localStorage.getItem("codigoArea-seguroautos")
      : "",
    telefono: localStorage.getItem("telefono-seguroautos")
      ? localStorage.getItem("telefono-seguroautos")
      : "",
    codigoPostal: localStorage.getItem("codigoPostal-seguroautos")
    ? localStorage.getItem("codigoPostal-seguroautos")
    : "",
    producto:
      landingVehiculo == "auto"
        ? "auto"
        : landingVehiculo == "pickup"
        ? "pickup"
        : landingVehiculo == "camion"
        ? "camion"
        : localStorage.getItem("producto-seguroautos") !== null
        ? localStorage.getItem("producto-seguroautos")
        : "auto",
  });
  // Errores del formulario
  const [formErrors, setFormErrors] = useState({});
  // Pop-up Errores
  const [popupErrores, setPopupErrores] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // IP
  const [ip, setIp] = useState("");
  // Params provenientes de la URL
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_campaign = searchParams.get("utm_campaign");
  const fb_id = searchParams.get("fbclid");
  const influencer = searchParams.get("influencer")
    ? searchParams.get("influencer")
    : 0;
  const [canal_url, setCanal_url] = useState(null);
  // Network Params
  const network = searchParams.get("network");
  const networkCampaign = searchParams.get("campaign");
  const networkGroup = searchParams.get("group");
  const networkCreative = searchParams.get("creative");
  const networkKeyword = searchParams.get("keyword");
  const networkDevice = searchParams.get("device");
  const networkMatchtype = searchParams.get("matchtype");
  const networkClid = searchParams.get("gclid");

  // Toggle Pop-up Errores
  const togglePopupErrores = () => {
    setPopupErrores(!popupErrores);
  };

  // Marketing URL
  useEffect(() => {
    if (marketing_url) {
      document.title = marketing_url.title_marketing_url;
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", marketing_url.description_marketing_url);
    }
  });

  //Agregar valores en localStorage Temporal
  useEffect(() => {
    // Guardar en localStorage
    localStorage.setItem("producto-seguroautos", "auto");
    localStorage.setItem("localidad-seguroautos", "");
    localStorage.setItem("provincia-seguroautos", "");
  }, []);

  // Setea el valor de los inputs
  function handleChange(e) {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
    // Guarda el valor de cada input en el localStorage con su nombre para autocompletar el paso 3
    localStorage.setItem([e.target.name + "-seguroautos"], e.target.value);
    if(e.target.name === "producto") actualizarVehiculo(e.target.value);
  }

  // Validar Formulario
  function validarForm(e) {
    e.preventDefault();
    let errors = {};
    // Validar nombre
    if (!inputValues.nombre) {
      errors.nombre = "Este nombre es requerido.";
    } else if (inputValues.nombre.length < 2) {
      errors.nombre = "El nombre debe contener al menos 2 caracteres.";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.nombre
      )
    ) {
      errors.nombre = "El nombre ingresado no es válido.";
    }
    // Validar email
    if (!inputValues.email) {
      errors.email = "Este correo electrónico es requerido.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.email)) {
      errors.email = "El correo electrónico ingresado no es valido.";
    }
    // Validar teléfono
    if (!inputValues.telefono) {
      errors.telefono = "El teléfono es requerido.";
    } else if (inputValues.telefono.length < 8) {
      errors.telefono = "El teléfono es menor a 8 dígitos.";
    }
    // Validar código de área
    if (!inputValues.codigoArea) {
      errors.codigoArea = "El código de área es requerido.";
    } else if (
      inputValues.codigoArea.length < 2 ||
      inputValues.codigoArea.length > 5
    ) {
      errors.codigoArea =
        "El código de área debe tener más de 2 dígitos y menos de 5.";
    }
    // Validar código de postal
    if (!inputValues.codigoPostal) {
      errors.codigoPostal = "El código postal es requerido.";
    } else if (
      inputValues.codigoPostal.length < 4
    ) {
      errors.codigoPostal =
        "El código de postal debe tener 4 dígitos.";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      sendData();
    } else if (popupErrores != true) {
      togglePopupErrores();
    }
  }

  const sendData = async () => {
    const origen = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    )
      ? "mobile"
      : "web";
    const landing_url = window.location.href.split("/");
    let landing;
    if (
      landing_url[3] === "seguro-de-auto" ||
      inputValues.producto === "auto"
    ) {
      landing = "auto";
    } else if (
      landing_url[3] === "seguro-de-pickup" ||
      inputValues.producto === "pickup"
    ) {
      landing = "pickup";
    } else if (
      landing_url[3] === "seguro-de-camion" ||
      inputValues.producto === "camion"
    ) {
      landing = "camion";
    }

    const data = {
      nombre: inputValues.nombre,
      email: inputValues.email,
      telefono: `(${inputValues.codigoArea}) ${inputValues.telefono}`,
      ip_adress: ip,
      autogestion: 0,
      marca: inputValues.marca,
      modelo: inputValues.modelo,
      version: inputValues.version,
      landing: landing,
      producto: inputValues.producto,
      localidad: '',
      provincia: '',
      codigo_postal: inputValues.codigoPostal,
      origen: origen,
      utm_source: utm_source ? utm_source : "",
      utm_medium: utm_medium ? utm_medium : "",
      utm_campaign: utm_campaign ? utm_campaign : "",
      influencer: influencer,
      canal_url: canal_url,
      fb_id: fb_id,
      network: network,
      networkCampaign: networkCampaign,
      networkGroup: networkGroup,
      networkCreative: networkCreative,
      networkKeyword: networkKeyword,
      networkDevice: networkDevice,
      networkMatchtype: networkMatchtype,
      networkClid: networkClid,
    };

    try {
      const post = await axios.post(`${url}/api/infoAutosCotizados/`, data);
      data.numeroId_autos = post.data.id;
      data.numeroOperacion = post.data.numero;

      if (post.status === 200) {
        setLoading(false);
        navigate(`/pasos/?op=${post.data.numero}`, { state: post.data });
      }
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <Container isReferidosHeaderOpen={isReferidosHeaderOpen} name="cotizador">
      {/* Pop up Errores */}
      {popupErrores == true && (
        <div id="popup-error">
          {/* Nombre */}
          {formErrors.nombre != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.nombre}
            </p>
          )}
          {/* Email */}
          {formErrors.email != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.email}
            </p>
          )}
          {/* Código de área */}
          {formErrors.codigoArea != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.codigoArea}
            </p>
          )}
          {/* Teléfono */}
          {formErrors.telefono != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.telefono}
            </p>
          )}

        </div>
      )}
      {/* Formulario Container */}
      <div id="form-titulo-container" className="opacidad05">
        {/* Titulos */}
        <div id="titulos-container">
          <h1 id="titulo-home" className="opacidad05">
            {marketing_url
              ? marketing_url.h1_marketing_url
              : "¡Cotizá tu seguro!"}
          </h1>
          <h3 id="subtitulo-home" className="opacidad05">
            {marketing_url
              ? marketing_url.h2_marketing_url
              : subtitle
              ? subtitle
              : "Te acompaña a donde vayas, vos elegí el camino que los une."}
          </h3>
        </div>
        {/* Formulario Container */}
        <div id="form-container" className="opacidad05">
          <form>
            {/* Inputs Container */}
            <div id="inputs-container">
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input Codigo de Area */}
              <div className="input-container" id="input-codigo-area-container">
                <input
                  type="number"
                  name="codigoArea"
                  id="input-codigo-area"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.codigoArea}
                  className={formErrors.codigoArea == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.codigoArea == null ||
                    inputValues.codigoArea == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-codigo-area"
                >
                  Cod. Área
                </label>
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="number"
                  id="input-telefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                  className={formErrors.telefono == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-telefono"
                >
                  Teléfono
                </label>
              </div>
              {/* Input Codigo de Postal */}
              <div className="input-container" id="input-codigo-postal-container">
                <input
                  type="number"
                  name="codigoPostal"
                  id="input-codigo-postal"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.codigoPostal}
                  className={formErrors.codigoPostal == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.codigoPostal == null ||
                    inputValues.codigoPostal == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-codigo-postal"
                >
                  Cod. Postal
                </label>
              </div>
            </div>
            {/* Botones Container */}
            <div id="botones-container">
              {/* Whatsapp Button */}
              <Tippy content="De Lun. a Vie. de 9 a 18hs" animation="scale">
                <a
                  href="https://api.whatsapp.com/send?phone=5491162255399&text=%C2%A1Hola%2C%20necesito%20ayuda%20para%20cotizar%20en%20SegruoAutos!%20Ingresé%20desde%20la%20web"
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  Necesito ayuda
                </a>
              </Tippy>
              {/* Cotizar Button */}
              <button
                type="submit"
                onClick={validarForm}
                className="submit-button"
              >
                Cotizá tu seguro
                <span>
                  <FaCarSide></FaCarSide>
                </span>
              </button>
            </div>
          </form>
          {loading ? (
            <div id="loading-container">
              <div id="animacion-loading">
                {/* Animacion */}
                <AnimacionLoading></AnimacionLoading>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import { BiError } from "react-icons/bi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import axios from "axios";
import url from "../../../../utils/url";
import Container from "./styled";

export default function PasoUno({ pasoSiguiente }) {
  // Valores de los inputs
  const [inputValues, setInputValues] = useState({
    nombre:
      localStorage.getItem("nombre-seguroauto") !== null
        ? localStorage.getItem("nombre-seguroauto")
        : "",
    apellido:
      localStorage.getItem("apellido-seguroauto") !== null
        ? localStorage.getItem("apellido-seguroauto")
        : "",
    email:
      localStorage.getItem("email-seguroauto") !== null
        ? localStorage.getItem("email-seguroauto")
        : "",
  });

  // Errores del formulario
  const [formErrors, setFormErrors] = useState({});

  // Pop-up Errores
  const [popupErrores, setPopupErrores] = useState(false);

  // Toggle Pop-up Errores
  const togglePopupErrores = () => {
    setPopupErrores(!popupErrores);
  };

  // Setea el valor de los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    // Guarda el valor de cada input en el localStorage con su nombre para autocompletar el paso 3
    localStorage.setItem([name], value + "-seguroauto");
  };

  // Captcha
  function onChange(value) {
    setInputValues((prev) => ({ ...prev, ["captcha"]: value }));
  }

  // Subir archivo CV
  function onFileUpload(event) {
    event.preventDefault();
    event.persist();
    if (event.target.files[0]) {
      const { name } = event.target;
      setInputValues((prev) => ({
        ...prev,
        [name]: {
          image: event.target.files[0],
          name: event.target.files[0].name,
        },
      }));
    }
  }

  // Borrar archivo CV
  const deleteFile = () => {
    setInputValues((prev) => ({ ...prev, ["cv"]: "" }));
  };

  // Validar Formulario
  const validateForm = (e) => {
    e.preventDefault();
    let errors = {};

    // Validar nombre
    if (!inputValues.nombre) {
      errors.nombre = "El nombre es requerido";
    } else if (inputValues.nombre.length < 2) {
      errors.nombre = "El nombre debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.nombre
      )
    ) {
      errors.nombre = "El nombre ingresado no es válido";
    }

    // Validar apellido
    if (!inputValues.apellido) {
      errors.apellido = "Este apellido es requerido";
    } else if (inputValues.apellido.length < 2) {
      errors.apellido = "El apellido debe contener al menos 2 caracteres";
    } else if (
      !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        inputValues.apellido
      )
    ) {
      errors.apellido = "El apellido ingresado no es válido";
    }

    // Validar correo electrónico
    if (!inputValues.email) {
      errors.email = "El correo electrónico es requerido";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.email)) {
      errors.email = "El correo electrónico ingresado no es valido";
    }

    // Validar Puesto
    if (!inputValues.puesto) {
      errors.puesto = "El puesto es requerido.";
    }

    // Validar Mensaje
    if (!inputValues.mensaje) {
      errors.mensaje = "Tu comentario es requerido.";
    }

    // Validar CV
    if (!inputValues.cv) {
      errors.cv = "Tu CV es requerido.";
    }

    // Validar captcha
    if (inputValues.captcha == undefined) {
      errors.captcha = "Confirme la verificación reCaptcha";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      sendMail();
      pasoSiguiente();
    } else if (popupErrores != true) {
      togglePopupErrores();
    }
  };

  // Sube archivos al server
  const sendFiles = async (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append("cv", file);
    const upFile = await axios.post(`${url}/api/upload_files`, bodyFormData);
    let path = upFile.data;
    return path;
  };

  // Enviar mail
  const sendMail = async () => {
    const mailContent = `
      <html><body>
        <h1>Formá Parte SeguroAuto</h1>
        <p><b>Nombre:</b> ${inputValues.nombre}</p>
        <p><b>Apellido:</b> ${inputValues.apellido}</p>
        <p><b>Email:</b> ${inputValues.email}</p>
        <p><b>LinkedIn:</b> ${inputValues.linkedin}</p>
        <p><b>Motivaciones:</b> ${inputValues.mensaje}</p>
        <p>CV adjunto en este mensaje</p>
      </body></html>
      `;

    const filePath = await sendFiles(inputValues.cv.image);

    if (filePath) {
      const mailData = {
        mail: ["desarrollo.seguroweb@gmail.com"],
        subject: `Formá Parte SeguroComercio`,
        htmlContent: mailContent,
        attachments: filePath,
      };

      const sendMail = await axios.post(
        `${url}/api/sendEmails?from=interno`,
        mailData
      );
    }
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      {/* Pop up Errores */}
      {popupErrores == true && (
        <div id="popup-error">
          {formErrors.nombre != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.nombre}
            </p>
          )}
          {formErrors.apellido != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.apellido}
            </p>
          )}
          {formErrors.email != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.email}
            </p>
          )}
          {formErrors.puesto != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.puesto}
            </p>
          )}
          {formErrors.mensaje != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.mensaje}
            </p>
          )}
          {formErrors.cv != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.cv}
            </p>
          )}
          {formErrors.captcha != null && (
            <p>
              <span>
                <BiError />
              </span>
              {formErrors.captcha}
            </p>
          )}
        </div>
      )}
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container" className="opacidad05">
          <h3>¡Formá parte de Gestión y Servicios!</h3>
          <p id="completa">
            Completá el siguiente formulario y envianos un mensaje con tus
            motivaciones, pronto nos comunicaremos para charlar con vos.
          </p>
          <form>
            <div id="datos-personales">
              <h4>Datos personales</h4>
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Apellido */}
              <div className="input-container" id="input-apellido-container">
                <input
                  type="text"
                  id="input-apellido"
                  name="apellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.apellido}
                  className={formErrors.apellido == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.apellido == null || inputValues.apellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-apellido"
                >
                  Apellido
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input Puesto */}
              <div className="input-container" id="input-puesto-container">
                <input
                  type="text"
                  id="input-puesto"
                  name="puesto"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.puesto}
                  className={formErrors.puesto == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.puesto == null || inputValues.puesto == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-puesto"
                >
                  Puesto
                </label>
              </div>
              {/* Input Linkedin */}
              <div className="input-container" id="input-linkedin-container">
                <input
                  type="text"
                  id="input-linkedin"
                  name="linkedin"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.linkedin}
                  className={formErrors.linkedin == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.linkedin == null || inputValues.linkedin == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-linkedin"
                >
                  LinkedIn
                </label>
              </div>
            </div>
            <div id="mensaje-motivo">
              <h4>Contanos tus motivaciones</h4>
              {/* Textarea Mensaje */}
              <div className="input-container" id="textarea-mensaje-container">
                <textarea
                  type="text"
                  id="input-mensaje"
                  name="mensaje"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.mensaje ? inputValues.mensaje : ""}
                  className={formErrors.mensaje == null ? "" : "input-error"}
                ></textarea>
                <label
                  className={
                    inputValues.mensaje == null || inputValues.mensaje == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="input-mensaje"
                >
                  Ingresá tu mensaje
                </label>
              </div>
            </div>
            <div id="cv-container">
              <h4>Subí tu CV</h4>
              {/* Subir CV */}
              {inputValues.cv ? (
                <></>
              ) : (
                <div id="cv-input-container">
                  <label for="cv-input" id="cv-boton">
                    <span>
                      <BsFillFileEarmarkPdfFill></BsFillFileEarmarkPdfFill>
                    </span>
                    Seleccionar
                  </label>
                  <input
                    type="file"
                    name="cv"
                    // Acepta PDFs, archivos de Word y texto plano
                    accept="application/pdf"
                    id="cv-input"
                    onChange={(e) => onFileUpload(e)}
                  ></input>
                </div>
              )}
              {inputValues.cv ? (
                <div id="archivo-container">
                  <a
                    id="archivo-boton"
                    href={URL.createObjectURL(inputValues.cv.image)}
                    target="_blank"
                  >
                    <b>{inputValues.cv.name}</b>
                  </a>
                  <span id="borrar-boton" onClick={deleteFile}>
                    <FiTrash></FiTrash>
                  </span>
                </div>
              ) : null}
            </div>
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={onChange}
              />
            </div>
            <div id="boton-container">
              <button type="submit" onClick={validateForm}>
                Enviar<span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" style={style}>
                <p>Email</p>
                <p id="plazos-texto">rrhh@gyssrl.com.ar</p>
                <div>
                  <p>Ver más</p>
                  <Boton to="/contacto">Contacto</Boton>
                  <Boton to="/sobre-nosotros">Sobre nosotros</Boton>
                  <Boton to="/politicas-y-terminos">Políticas y términos</Boton>
                  <Boton to="/referidos">Referidos</Boton>
                </div>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}

import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Botón Atras Container
  .boton-atras-container {
    width: 100%;
    max-width: 1200px;
    margin: 0px 0px -32px 0px;
    display: flex;
    justify-content: flex-start;
    z-index: 98;
  }

  // Botón Atras
  .boton-atras-container button {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    background-color: var(--blanco);
    border: 0px;
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  .boton-atras-container button span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover
  .boton-atras-container button:hover {
    color: var(--azul60);
  }

  // Botón Atras Hover Icono
  .boton-atras-container button:hover > span {
    color: var(--azul60);
    transform: translate(-10px, 0);
  }

  // Valor Vehículo
  .valor-vehiculo-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
  }

  // Valor Vehículo Span
  .valor-vehiculo-container span {
    color: var(--negro);
    font-size: 14px;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  // Valor Vehículo Span Texto
  .valor-vehiculo-container span p {
    margin: 0px 0px 0px 10px !important;
    color: var(--azul60) !important;
    font-weight: bold !important;
  }

  // Referencias Container
  .referencias-container {
    width: 1240px !important;
    margin: 0px 0px 0px 0px;
    padding: 96px 0px 24px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid var(--grisClaro);
    position: sticky;
    top: ${(props) => (props.scroll == "arriba" ? "0px" : "-72px")};
    z-index: 97;
    transition: 0.2s all;
  }

  // Referencias
  .referencias {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
  }

  // Referencias Texto
  .referencias p {
    width: 100%;
    max-width: 120px;
    margin: 0px 0px 0px 0px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }

  // Cuotas Mensuales
  #cuotas-mensuales {
    max-width: 150px;
  }

  // Dropdowns Container
  .dropdowns-container {
    width: 100%;
    margin: 0px 0px 220px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Dropdown Container
  .dropdown-container {
    width: 100%;
    max-width: 1078px;
    margin: 48px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--grisClaro);
    border-radius: 16px;
    background-color: var(--blanco);
    box-shadow: 0px 5px 30px var(--sombraAzul);
    transition: 0.2s all;
  }

  // Datos Container
  .datos-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Plan Container
  .plan-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Plan Container Text
  .plan-container p {
    margin: 0px 4px 0px 4px;
    text-align: center;
    font-weight: 700;
    color: var(--verde50);
  }

  // Detalles Beneficios Container
  .detalles-beneficios-container {
    width: 100%;
    margin: 32px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Detalles Beneficios Container Titulo
  .detalles-beneficios-container h4 {
    width: 100%;
    margin: 0px 0px 32px 0px;
    text-align: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Detalles Beneficios Container Titulo Span
  .detalles-beneficios-container h4 span {
    margin: 4px 10px 0px 0px;
    color: var(--azul60);
    font-size: 15px;
  }

  // Detalles Container y Beneficios Container
  .detalles-container,
  .beneficios-container {
    width: 40%;
    margin: 0% 3% 0% 3%;
    max-width: 400px;
  }

  // None Benfeicios
  #none-beneficios {
    display: none !important;
  }

  // Detalles Container Titulo y Beneficios Container Titulo
  .detalles-container h4,
  .beneficios-container h4 {
    margin: 0px 0px 24px 0px;
    text-align: left;
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Detalles Container Titulo Icono y Beneficios Container Titulo Icono
  .detalles-container h4 span,
  .beneficios-container h4 span {
    margin: 4px 10px 0px 0px;
    color: var(--azul60);
    font-size: 15px;
  }

  // Detalle y Beneficio
  .detalle,
  .beneficio {
    width: 100%;
    margin: 0px 0px 3px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Detalles Container Centrado Detalle
  #centrado .detalle {
    justify-content: center;
  }

  // Detalle Icono y Beneficio Icono
  .detalle span,
  .beneficio span {
    margin-top: 4px;
    color: var(--azul60);
    font-size: 13px;
  }

  // Detalle Texto y Beneficio Text
  .detalle p,
  .beneficio p {
    margin: 0px 0px 0px 12px;
    text-align: left;
    font-size: 13px;
    line-height: 23px;
    white-space: pre-line;
  }

  // Vigencia Container
  .vigencia-container {
    width: 100%;
    margin: 48px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Vigencia Container Text
  .vigencia-container p {
    width: 100%;
    margin: 8px 0px 0px 0px;
    text-align: center;
    font-size: 14px;
    line-height: 27px;
    font-weight: 600;
    color: var(--verde50);
  }

  // Vigencia Container Botones
  .vigencia-container a {
    margin: 24px 0px 32px 0px;
    padding: 8px 24px 8px 24px;
    background-color: var(--azul60);
    color: var(--blanco);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Vigencia Container Botones Hover
  .vigencia-container a:hover {
    background-color: var(--azul20);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 1000px;
    }

    // Referencias Container
    .referencias-container {
      max-width: 1100px;
    }

    // Referencias
    .referencias {
      max-width: 690px;
    }

    // Dropdown Container
    .dropdown-container {
      max-width: 1018px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 680px;
      margin: 40px 0px 0px 0px;
    }

    // Referencias
    .referencias-container {
      display: none;
    }

    // Dropdowns Container
    .dropdowns-container {
      max-width: 490px;
      margin: 40px 20px 0px 20px;
    }

    // Dropdown Container
    .dropdown-container,
    .dropdown-mejor-precio,
    .dropdown-recomendado {
      margin: 40px 0px 0px 0px;
      max-width: 328px;
    }

    // Datos Container
    .datos-container {
      width: 80%;
    }

    // Detalles Container y Beneficios Container
    .detalles-container,
    .beneficios-container {
      width: 100%;
      margin: 15px 0px 0px 0px;
      max-width: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    // Beneficios Container
    .beneficios-container {
      margin: 0px 0px 0px 0px;
    }

    // Vigencia Container
    .vigencia-container {
      width: 100%;
      margin: 32px 0px 0px 0px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 350px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 85%;
    }

    // Dropdowns Container
    .dropdowns-container {
      display: flex;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Dropdown Container
    .dropdown-container,
    .dropdown-mejor-precio,
    .dropdown-recomendado {
      max-width: 85%;
    }
  }
`;

export default Container;

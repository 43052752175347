import React, { useEffect, useState } from "react";
import Logos from "../components/home/logos";
import styled from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";
import Cotizador from "../components/cotizador";
import Cubrimos from "../components/home/cubrimos";
import Pasos from "../components/home/pasos";
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function SeguroCamionContainer({ data }) {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  // Referidos Header
  const [isReferidosHeaderOpen, setReferidosHeaderOpen] = useState(true);
  const toggleReferidosHeader = () => {
    setReferidosHeaderOpen(!isReferidosHeaderOpen);
  };

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header
          isReferidosHeaderOpen={isReferidosHeaderOpen}
          toggleReferidosHeader={toggleReferidosHeader}
        ></Header>
      </Headroom>
      {/* Cotizador */}
      <Cotizador
        isReferidosHeaderOpen={isReferidosHeaderOpen}
        subtitle="¡Asegurá tu camión con nosotros!"
        landingVehiculo="camion"
        marketing_url={data}
      />
      {/* Logos */}
      <Logos />
      {/* Cubrimos */}
      <Cubrimos />
      {/* Pasos */}
      <Pasos vehiculo="camion"></Pasos>
      <Footer />
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import axios from "axios";
import url from "../src/utils/url";
import GlobalStyle from "./constants/globalStyles";
import HomeContainer from "./containers/home-container";
import Steps from "./containers/Steps";
import ContactoContainer from "./containers/contacto-container";
import ReferidosContainer from "./containers/referidos-container";
import BajaContainer from "./containers/baja-container";
import ArrepentimientoContainer from "./containers/arrepentimiento-container";
import FormaParteContainer from "./containers/formaparte-container";
import InstitucionalContainer from "./containers/institucional-container";
import SeguroAutoContainer from "./containers/SeguroAutoContainer";
import SeguroPickupContainer from "./containers/SeguroPickupContainer";
import SeguroCamionContainer from "./containers/SeguroCamionContainer";
import ErrorPage from "./containers/ErrorPage";

function App() {
  const [cotizador, setCotizador] = useState("");
  const [urls, setUrls] = useState({});

  const location = useLocation();

  const cotizadoresToRender = [
    {
      component: SeguroAutoContainer,
      producto: "Auto",
    },
    {
      component: SeguroPickupContainer,
      producto: "Pickup",
    },
    {
      component: SeguroCamionContainer,
      producto: "Camion",
    },
    {
      component: HomeContainer,
      producto: "Todos",
    },
  ];

  useEffect(() => {
    const getUrls = async () => {
      if (location.pathname !== "/") {
        try {
          let urlPath = location.pathname.replace("/", "");
          const urls = await axios.get(`${url}/api/marketing_url/${urlPath}`);
          if (urls.data) {
            const renderCotizador = cotizadoresToRender.find(
              (cotizadorToRender) =>
                cotizadorToRender.producto === urls.data.producto_marketing_url
            );
            setCotizador(renderCotizador);
            setUrls(urls.data);
          }
        } catch (err) {
          console.log(err);
          setUrls("");
        }
      }
    };
    getUrls();
  }, []);

  return (
    <div className="App">
      <Routes>
        {urls && cotizador && (
          <Route
            exact
            path={urls.url_marketing_url}
            element={<cotizador.component data={urls} />}
          />
        )}
        {/* Home */}
        <Route exact path="/" element={<HomeContainer />} />
        {/* Pasos */}
        <Route path="/pasos" element={<Steps />} />
        {/* Contacto */}
        <Route path="/contacto" element={<ContactoContainer />} />
        {/* Forma Parte */}
        <Route path="/forma-parte" element={<FormaParteContainer />} />
        {/* Seguro de Auto */}
        <Route path="/seguro-de-auto" element={<SeguroAutoContainer />} />
        {/* Seguro de Pickup */}
        <Route path="/seguro-de-pickup" element={<SeguroPickupContainer />} />
        {/* Seguro de Camión */}
        <Route path="/seguro-de-camion" element={<SeguroCamionContainer />} />
        {/* Referidos */}
        <Route path="/referidos" element={<ReferidosContainer />} />
        {/* Baja de Servicio */}
        <Route path="/baja-de-servicio" element={<BajaContainer />} />
        {/* Arrepentimiento de Compra */}
        <Route
          path="/arrepentimiento-de-compra"
          element={<ArrepentimientoContainer />}
        />
        {/* Políticas y Términos */}
        <Route
          path="/politicas-y-terminos"
          element={
            <InstitucionalContainer
              pagina="politicas-y-terminos"
              tituloSpan="Políticas y términos"
              titulo=" - Gestión y Servicios"
            />
          }
        />
        {/* Sobre Nosotros */}
        <Route
          exact
          path="/sobre-nosotros"
          element={
            <InstitucionalContainer
              pagina="sobre-nosotros"
              tituloSpan="Sobre nosotros"
              titulo=" - Gestión y Servicios"
            />
          }
        />
        {!urls && <Route path="/*" element={<ErrorPage />} />}
      </Routes>
      <GlobalStyle></GlobalStyle>
    </div>
  );
}

export default App;

import * as React from "react";
import PasoUno from "../pasoUno";
import Confirmacion from "../confirmacion";

export default class FormularioSwitch extends React.Component {
  state = {
    paso: 1,
  };

  pasoSiguiente = () => {
    const { paso } = this.state;
    this.setState({ paso: paso + 1 });
  };

  render() {
    const { paso } = this.state;
    switch (paso) {
      case 1:
        return <PasoUno pasoSiguiente={this.pasoSiguiente}></PasoUno>;
      case 2:
        return <Confirmacion></Confirmacion>;
      default:
      // No hace nada
    }
  }
}

import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 40%;
  max-width: 620px;
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;

  // Pop Up Errores
  #popup-error {
    width: auto;
    max-width: 380px;
    height: auto;
    margin: 0px 0px 40px 40px;
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--blanco);
    border-radius: 16px;
    border: 1px solid var(--grisClaro);
    box-shadow: 0px 4px 20px var(--sombraAzul);
    transform-origin: top center;
    animation-name: animation-in;
    animation-duration: 0.4s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
    z-index: 100;
  }

  // Animación Entrada Popup Erorres
  @keyframes animation-in {
    from {
      transform: translate(0px, 300px);
    }
    to {
      transform: translate(0px, 0px);
    }
  }

  // Pop Up Errores Ícono
  #popup-error span {
    margin: 3px 10px 0px 0px;
    color: var(--naranja100);
    font-size: 17px;
  }

  // Pop Up Errores Texto
  #popup-error p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    color: var(--gris);
    font-size: 13.5px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
  }

  // Formulario Titulo Container
  #form-titulo-container {
    width: 740px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    z-index: 60;
  }

  // Titulos Container
  #titulos-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulos
  h1,
  h3 {
    width: 100% !important;
    margin: 0px 0px 8px 0px;
    color: var(--negro);
    letter-spacing: -0.015em;
  }

  h3 {
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
  }

  // Formulario Container
  #form-container {
    width: 80%;
    margin: 56px 0px 0px 0px;
    padding: 32px 32px 32px 32px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: var(--blanco);
    border-radius: 12px;
    box-shadow: 0px 5px 30px var(--sombraAzul);
    border: 1px solid var(--grisClaro);
  }

  // Formulario
  form {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Inputs Container
  #inputs-container {
    width: 100%;
    margin: 4px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Input Container
  #inputs-container .input-container,
  #inputs-container .select-container {
    width: 48%;
    margin: 0px 0px 24px 0px;
    position: relative;
  }

  // Input, Select and Label
  #inputs-container .input-container input,
  #inputs-container .input-container label,
  #inputs-container .select-container select {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
  }

  // Select Label
  #inputs-container .select-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  // Inputs and Select
  #inputs-container .input-container input,
  #inputs-container .select-container select {
    width: 100%;
    height: 35px;
    padding: 0px 16px 0px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    color: var(--gris);
    border: 1px solid rgba(152, 152, 154, 0.6);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // Label
  #inputs-container .input-container label,
  #inputs-container .select-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 16px;
    line-height: 1;
    font-size: 0.75em;
    font-weight: 400;
    color: rgba(152, 152, 154, 0.6);
    cursor: text;
    z-index: 60;
  }

  // Input and Select On Focus Label
  #inputs-container .input-container input:focus + label,
  #inputs-container .select-container select:focus + label {
    top: -0.6em;
    background-color: var(--blanco);
    padding: 0px 6px 0px 8px;
    margin: 0px 0px 0px 12px;
    font-weight: 500;
    font-size: 0.7em;
    color: var(--azul60) !important;
    z-index: 59 !important;
  }

  // Inputs and Select Focus
  #inputs-container .input-container input:focus,
  #inputs-container .select-container select:focus {
    border: 1px solid var(--azul60);
    outline: none;
    z-index: 59 !important;
  }

  // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: var(--blanco);
    padding: 0px 6px 0px 8px;
    margin: 0px 0px 0px 12px !important;
    font-weight: 500 !important;
    font-size: 0.7em !important;
    color: rgba(152, 152, 154, 0.6) !important;
    z-index: 59 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  // Select Container
  .select-container {
    position: relative;
    height: 35px;
    width: 100%;
    max-width: 330px;
    margin: 0px 0px 24px 0px !important;
  }

  // Select
  .select-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 100%;
    padding: 0px 16px 0px 16px;
    margin: 0px 0px 0px 0px;
    height: 100%;
    cursor: pointer;
    outline: 0;
    border: 1px solid rgba(152, 152, 154, 0.6);
    font-style: normal;
    font-weight: normal;
    color: var(--gris);
    position: relative;
    transition: all 0.25s ease;
    z-index: 60;
    background-color: transparent;
    border-radius: 0;
    -webkit-appearance: none;
    box-shadow: 0;
    border-radius: 3px;
    font-size: 0.75em;
    text-transform: capitalize !important;
  }

  // Select Flecha Container
  .flecha-select-container {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    z-index: 59;
    transition: 0.2s all;
  }

  // Select Arrow
  .select-container i {
    width: 7px;
    height: 7px;
    margin: -4px 0px 0px 0px;
    display: block;
    border-left: 3px solid rgba(152, 152, 154, 0.6);
    border-bottom: 3px solid rgba(152, 152, 154, 0.6);
    transform: rotate(-45deg);
    border-radius: 10%;
    transition: 0.3s all;
  }

  #inputs-container
    .select-container
    select:focus
    ~ .flecha-select-container
    i {
    margin: 3px 0px 0px 0px !important;
    transform: rotate(135deg) !important;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: var(--negro);
  }

  // ----------------------------------------------------------

  // Disable
  .disable {
    pointer-events: none;
    opacity: 0.4;
  }

  // Input Error
  .input-error {
    border: 1px solid red !important;
  }

  // Botones Container
  #botones-container {
    width: 100%;
    margin: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  #botones-container a {
    background-color: var(--blanco);
    color: var(--verde50);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-decoration: none;
  }

  #botones-container a span {
    font-size: 17px;
    margin: 4px 8px 0px 0px;
  }

  #botones-container button {
    padding: 7px 24px 7px 24px;
    border-radius: 8px;
    border: 0px solid;
    color: var(--blanco);
    background-color: var(--azul60);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #botones-container button span {
    margin: 0px 0px 0px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 18px;
    transition: 0.3s;
  }

  #botones-container button:hover {
    background-color: var(--azul0) !important;
  }

  #botones-container button:hover > span {
    transform: translate(8px);
  }

  // Animacion Container
  #animacion-container {
    width: calc(100% - 830px);
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    z-index: 40;
  }

  // Loading Container
  #loading-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    border-radius: 12px;
    z-index: 59;
    animation-name: animation-loading;
    animation-duration: 0.1s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
  }

  // Animación Entrada Loading
  @keyframes animation-loading {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // Animación Loading
  #animacion-loading {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
 
    width: 100%;
    margin: 40px;
    position: relative;
    justify-content: space-around;

    // Formulario Titulo Container
    #form-titulo-container {
      width: 530px;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-email-container {
      width: 48.5% !important;
      max-width: 48.5% !important;
    }

    #input-codigo-area-container {
      width: 20% !important;
      max-width: 20% !important;
    }

    #input-telefono-container {
      width: 26% !important;
      max-width: 26% !important;
    }

    // Animacion Container
    #animacion-container {
      width: calc(100% - 400px);
      margin: -100px 0px 0px 0px;
      position: absolute;
      top: 135px;
      right: -50px;
    }
  
    // 1550px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1550}) {
    width: 100%;
    
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    width: 40%;
    max-width: 40%;
    margin-top: 50px;
    justify-content: center;

    // Bicicleteria Logo Container
    #bicicleteria-logo-container {
      margin: 0px 0px 0px 0px;
    }

    // Titulos Container
    #titulos-container {
      width: 100%;
    }

    // Titulos
    h1,
    h3 {
      text-align: center;
    }

    // Formulario Titulo Container
    #form-titulo-container {
      width: 100%;
      justify-content: center;
    }

    // Animacion Container
    #animacion-container {
      display: none;
    }
  }

  // 1200px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: 580px;
    margin-top: 40px;
    justify-content: center;

    // Bicicleteria Logo Container
    #bicicleteria-logo-container {
      margin: 0px 0px 0px 0px;
    }

    // Titulos Container
    #titulos-container {
      width: 100%;
    }

    // Titulos
    h1,
    h3 {
      text-align: center;
    }

    // Formulario Titulo Container
    #form-titulo-container {
      width: 100%;
      justify-content: center;
    }

    // Animacion Container
    #animacion-container {
      display: none;
    }
  } 

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    min-width: 60%;
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 504px;
    // margin-top: 110px;

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #input-codigo-area-container,
    #input-codigo-postal-container,
    #input-telefono-container {
      width: 100%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    margin-top: 72px;
    width: 92%;

    // Formulario Container
    #form-container {
      width: 80%;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-email-container,
    #input-codigo-area-container,
    #input-telefono-container,
    #input-codigo-postal-container {
      width: 100% !important;
      max-width: 100% !important;
    }

    #botones-container {
      flex-direction: column-reverse;
    }

    #botones-container a {
      width: 100%;
      margin: 20px 0px 0px 0px;
      justify-content: center;
    }

    #botones-container button {
      width: 100%;
      max-width: 300px;
      padding: 8px 24px 8px 24px;
    }
  }

  // 470px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile470}) {
    width: 90%;

    // Titulos
    h1 {
      font-size: 28px;
    }

    h3 {
      font-size: 14px;
    }

    // Formulario Container
    #form-container {
      margin: 30px 0px 0px 0px;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-email-container,
    #input-codigo-area-container,
    #input-telefono-container,
    #input-codigo-postal-container {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;

export default Container;

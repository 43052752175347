import React, { useEffect, useState } from "react";
import Container from "./styled";
import Marcador from "../../Marcador";
import { BsArrowLeft } from "react-icons/bs";
import {
  MdPersonOutline,
  MdCreditCard,
  MdOutlineTextSnippet,
  MdAllInbox,
} from "react-icons/md";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Tarjeta from "./tarjeta";
import valid from "card-validator";
import url from "../../../utils/url";
import "material-symbols";

export default function Contrata({
  prevStep,
  nextStep,
  idTablaAutos,
  planSelected,
}) {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    async function getData() {
      let data = await axios.get(
        `${url}/api/infoAutosCotizados/${idTablaAutos}`
      );
      setDataOperacion(data.data);
    }
    getData();
  }, []);

  const [dataOperacion, setDataOperacion] = useState(null);

  const [userInfo, setUserInfo] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    nombre: localStorage.getItem("nombre-seguroautos"),
    apellido: "",
    cuitDNI: "",
    email: localStorage.getItem("email-seguroautos"),
    codigo_area: localStorage.getItem("codigoArea-seguroautos"),
    telefono: localStorage.getItem("telefono-seguroautos"),
    producto: localStorage.getItem("producto-seguroautos"),
    ano: localStorage.getItem("ano-seguroautos"),
    cbu: "",
    dniTarjeta: "",
    banco: "",
    formaDePago: "Tarjeta de credito",
    prefijos: "",
    tipo_tarjeta: "",
  });

  const [isEnviarButtonDisabled, setIsEnviarButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const search = useLocation().search;
  const query = new URLSearchParams(search).get("op");

  // useEffect(() => {
  //   const codigos_area = axios.get(
  //     "https://heroku-gys-server-test.herokuapp.com/prefijos"
  //   );
  //   setUserInfo({ ...userInfo, prefijos: codigos_area.data });
  // }, []);

  function handleInputChange(e) {
    // Condicional para no guardar ningun tipo de dato de pago en el localStorage
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
    if (
      e.target.name !== "cvc" &&
      e.target.name !== "expiry" &&
      e.target.name !== "name" &&
      e.target.name !== "suma_asegurada" &&
      e.target.name !== "formaDePago" &&
      e.target.nameame !== "cbu" &&
      e.target.name !== "dniTarjeta" &&
      e.target.name !== "banco"
    ) {
      localStorage.setItem([e.target.name + "-seguroautos"], e.target.value);
    }
  }

  function checkErrors() {
    let errors = {};

    // Nombre
    if (!userInfo.nombre) {
      errors.nombre = "Campo requerido";
    } else if (userInfo.nombre.length < 2) {
      errors.nombre = "El campo debe contener al menos 2 caracteres.";
    } else if (
      !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
        userInfo.nombre
      )
    ) {
      errors.nombre = "El nombre ingresado no es válido.";
    }
    // Apellido
    if (!userInfo.apellido) {
      errors.apellido = "Campo requerido";
    } else if (userInfo.apellido.length < 2) {
      errors.apellido = "El campo debe contener al menos 2 caracteres.";
    } else if (
      !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(userInfo.apellido)
    ) {
      errors.apellido = "El apellido ingresado no es válido.";
    }

    // CUIT / DNI
    if (!userInfo.cuitDNI) {
      errors.cuitDNI = "Campo requerido";
    } else if (userInfo.cuitDNI.length < 8 || userInfo.cuitDNI.length > 11) {
      errors.cuitDNI = "El CUIT/DNI es inválido.";
    }

    // Código Postal
    // if (!userInfo.codigo_postal) {
    //   errors.codigo_postal = "Campo requerido";
    // }

    // Email
    if (!userInfo.email) {
      errors.email = "Campo requerido";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInfo.email)) {
      errors.email = "El email ingresado no es valido.";
    }

    // Código de área
    if (!userInfo.codigo_area) {
      errors.codigo_area = "Campo requerido";
    } else if (
      userInfo.codigo_area.length < 2 ||
      userInfo.codigo_area.length > 5
    ) {
      errors.codigo_area =
        "El código de área debe tener más de 2 digitos y menos de 5.";
    }

    // Teléfono
    if (!userInfo.telefono) {
      errors.telefono = "Campo requerido";
    } else if (userInfo.telefono.length < 8) {
      errors.telefono = "El campo telefono es menor a 8 digitos.";
    }

    // Producto
    if (!userInfo.producto) {
      errors.producto = "Campo requerido";
    }

    // Año
    if (!userInfo.ano) {
      errors.ano = "Campo requerido";
    }

    // Forma de Pago
    if (!userInfo.formaDePago) {
      errors.formaDePago = "Campo requerido";
    } else {
      // Tarjeta de crédito
      if (userInfo.formaDePago === "Tarjeta de credito") {
        // Titular de la tarjeta
        if (!userInfo.name) {
          errors.name = "Campo requerido";
        } else {
          let validName = valid.cardholderName(userInfo.name);
          if (!validName.isValid) {
            errors.name = "El nombre ingresado no es válido.";
          }
          if (userInfo.name.length < 2) {
            errors.name =
              "El campo Titular de la tarjeta debe contener al menos 2 caracteres";
          } else if (
            !/^[A-Za-z'\s\u00f1\u00d1\u00C0-\u017F]+$/.test(userInfo.name)
          ) {
            errors.name = "El nombre ingresado no es válido.";
          }
        }

        // Número de tarjeta
        if (!userInfo.number) {
          errors.number = "Campo requerido";
        } else if (
          userInfo.number.startsWith("4517") ||
          userInfo.number.startsWith("4815") ||
          userInfo.number.startsWith("5285")
        ) {
          errors.number =
            "El número de tarjeta ingresado corresponde a una tarjeta de debito.";
        } else {
          let validNumber = valid.number(userInfo.number);
          if (!validNumber.isValid) {
            errors.number = "El número de tarjeta ingresado no es válido.";
          } else {
            setUserInfo({
              ...userInfo,
              tipo_tarjeta: validNumber.card.niceType,
            });
          }
        }

        // Fecha de vencimiento
        if (!userInfo.expiry) {
          errors.expiry = "Campo requerido";
        } else {
          let validExpiry = valid.expirationDate(userInfo.expiry);
          if (!validExpiry.isValid) {
            errors.expiry = "La fecha de vencimiento ingresada no es válida.";
          }
        }

        // DNI
        if (!userInfo.dniTarjeta) {
          errors.dniTarjeta = "Campo requerido";
        } else if (userInfo.dniTarjeta.length !== 8) {
          errors.dniTarjeta = "El campo DNI solo permite 8 digitos.";
        }

        // Banco Emisor
        if (!userInfo.banco) {
          errors.banco = "Campo requerido";
        } else if (!/^[A-Za-z'\s\u00C0-\u017F]+$/.test(userInfo.banco)) {
          errors.banco = "El Banco emisor ingresado no es válido.";
        }
      } else if (userInfo.formaDePago === "Transferencia bancaria") {
        let notValid = ["0143", "0147", "0158", "0165", "0384", "000000"];

        notValid.forEach((number) => {
          if (userInfo.cbu.startsWith(number)) {
            errors.cbu = "CBU no bancarizado.";
          }
        });

        //     // CBU
        if (!userInfo.cbu) {
          errors.cbu = "Campo requerido";
        } else if (userInfo.cbu.length !== 22) {
          errors.cbu = `El CBU ingresado debe contener 22 digitos. El valor ingresado tiene ${userInfo.cbu.length} digitos.`;
        }
      }
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsEnviarButtonDisabled(true);
      setLoading(true);
      sendData();
      sendEmail();
      nextStep(true, true);
      setLoading(false);
    }
  }

  async function sendData() {
    const pago = {
      nombre: userInfo.nombre + " " + userInfo.apellido,
      telefono: `(${userInfo.codigo_area}) ${userInfo.telefono}`,
      dni: userInfo.cuitDNI,
      email: userInfo.email,
      numOp: query,
      producto: localStorage.getItem("producto-seguroautos"),
      plan: planSelected.plan,
      compania: planSelected.seguro,
      valor_contratado: planSelected.precio,
      formaDePago: userInfo.formaDePago,
      tipo_tarjeta: userInfo.tipo_tarjeta,
      name: userInfo.name,
      number: userInfo.number,
      cvc: userInfo.cvc,
      expiry: userInfo.expiry,
      cbu: userInfo.cbu,
      dniTarjeta: userInfo.dniTarjeta,
      banco_emisor: userInfo.banco,
    };
    await axios.post(`${url}/api/contratacion/`, pago);

    axios.post(`${url}/api/sendEmails/contratacion/${query}`, pago);
  }

  async function sendEmail() {
    const data = {
      nombre: userInfo.nombre + " " + userInfo.apellido,
      telefono: `(${userInfo.codigo_area}) ${userInfo.telefono}`,
      dni: userInfo.cuitDNI,
      email: userInfo.email,
      numOp: query,
      autogestion: 1,
      plan: planSelected.plan,
      compañia: planSelected.seguro,
      valor: planSelected.precio,
      formaDePago: userInfo.formaDePago,
      name: userInfo.name,
      number: userInfo.number,
      cvc: userInfo.cvc,
      expiry: userInfo.expiry,
      cbu: userInfo.cbu,
      dniTarjeta: userInfo.dniTarjeta,
      banco: userInfo.banco,
      codigoPostal: dataOperacion.sw_operacione.codigo_postal,
      provincia: dataOperacion.sw_operacione.provincia,
      localidad: dataOperacion.sw_operacione.localidad,
      marca: dataOperacion.marca,
      modelo: dataOperacion.modelo,
      version: dataOperacion.version,
      suma_asegurada: dataOperacion.suma_asegurada,
      producto: dataOperacion.producto,
      ano: dataOperacion.ano,
    };

    await axios.post(`${url}/api/sendEmails/contratacion/${query}`, data);
  }

  // Tarjeta
  // function handleInputFocus(e) {
  //   this.setState({ focus: e.target.name });
  // };

  // Obtiene la fecha actual y se updatea solo todos los meses para que nunca puedan poner tarjetas vencidas
  const fecha = new Date();
  const obtenerMesActual = fecha.getMonth() + 1;
  const mesActual =
    obtenerMesActual == 1
      ? "01"
      : obtenerMesActual == 2
      ? "02"
      : obtenerMesActual == 3
      ? "03"
      : obtenerMesActual == 4
      ? "04"
      : obtenerMesActual == 5
      ? "05"
      : obtenerMesActual == 6
      ? "06"
      : obtenerMesActual == 7
      ? "07"
      : obtenerMesActual == 8
      ? "08"
      : obtenerMesActual == 9
      ? "09"
      : obtenerMesActual;
  const añoActual = fecha.getFullYear();

  let localidad = dataOperacion?.sw_operacione.localidad
    .toLowerCase()
    .split(" ");
  localidad = localidad
    ?.map((e) => {
      return e[0].toUpperCase() + e.substring(1);
    })
    .join(" ");

  return (
    <Container>
      <Marcador
        pasoUno="hecho"
        pasoDos="hecho"
        pasoTres="haciendo"
        pasosTerminados=""
        titulo="¡Ya elegiste tu seguro!"
      ></Marcador>
      {/* Botón Atras Container */}
      <div className="boton-atras-container">
        <button type="button" onClick={prevStep}>
          <span>
            <BsArrowLeft />
          </span>
          Volvé a elegir
        </button>
      </div>
      {/* Max Width Coberturas Container */}
      <div id="max-width-coberturas-container">
        {/* Coberturas Container */}
        <div className="coberturas-container">
          {/* Logo y Plan Container */}
          <div className="logo-plan-container">
            <img
              alt="Logo Seguro"
              src={planSelected.logo}
              id={
                planSelected.seguro == "HDI Seguros"
                  ? "logo-hdi"
                  : planSelected.seguro == "Parana Seguros" && "logo-parana"
              }
            ></img>
            <p>{planSelected.plan}</p>
          </div>
          {/* Checks Container */}
          <div className="checks-container">
            {/* Responsabilidad Civil */}
            <span>
              {planSelected.resCivil == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
              <p>Resp. Civil</p>
            </span>
            {/* B1 Cobertura Total */}
            <span>
              {planSelected.b1CobTotal == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
              <p>B1 Cober. Total</p>
            </span>
            {/* Terceros completos */}
            <span>
              {planSelected.tercComp == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
              <p>Terceros Comp.</p>
            </span>
            {/* Terceros Completos Full */}
            <span>
              {planSelected.tercCompFull == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
              <p>Ter. Comp. Full</p>
            </span>
            {/* Todo Riesgo */}
            <span>
              {planSelected.todoRiesgo == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
              <p>Todo Riesgo</p>
            </span>
            {/* Precios Container */}
            <div className="precio-container">
              {/* Cuotas Container */}
              <div>
                {planSelected.cuotas == "" ? null : (
                  <p
                    id={planSelected.precio == "" && "cuotas-bold"}
                    className={
                      planSelected.precioAnterior == "" && "cuotas-azul"
                    }
                  >
                    {planSelected.cuotas}
                  </p>
                )}
              </div>
              {/* Precio */}
              {planSelected.precio == "" ? null : (
                <div>
                  <p>${planSelected.precio}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Formulario */}
      <form>
        {/* Datos Cotización Container */}
        <div className="datos-cotizacion-container">
          <p>
            <span>
              <MdAllInbox />
            </span>
            Datos de cotización
          </p>
          {/* Datos Cotizacion */}
          <div id="datos-cotizacion">
            {/* Suma Asegurada */}
            <div className="dato">
              <p>Suma asegurada</p>
              <NumberFormat
                value={dataOperacion?.suma_asegurada}
                className="foo"
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value, props) => <p {...props}>{value}</p>}
              />
            </div>
            {/* Plan Seleccionado */}
            <div className="dato">
              <p>Plan</p>
              <p>{planSelected.seguro == "Parana Seguros" && "Paraná"}</p>
              <p>{planSelected.seguro == "HDI Seguros" && "HDI"}</p>
              <p>{planSelected.seguro == "Provincia Seguros" && "Provincia"}</p>
              <p>{planSelected.seguro == "Prudencia Seguros" && "Prudencia"}</p>
            </div>
            {/* Tipo de vehículo */}
            <div className="dato" id="dato-vehiculo">
              <p>Tipo de vehículo</p>
              <p>
                {localStorage.getItem("producto-seguroautos") == "auto" &&
                  "Auto"}
                {localStorage.getItem("producto-seguroautos") == "pickup" &&
                  "Pickup"}
                {localStorage.getItem("producto-seguroautos") == "camion" &&
                  "Camión"}
              </p>
            </div>
            {/* Localidad */}
            <div className="dato" id="dato-localidad">
              <p>Localidad</p>
              <p>{localidad}</p>
            </div>
          </div>
        </div>
        {/* Datos del vehículo */}
        <div className="datos-vehiculo-container">
          <p>
            <span>
              <MdOutlineTextSnippet></MdOutlineTextSnippet>
            </span>
            {"Datos de tu " + localStorage.getItem("producto-seguroautos")}
          </p>
          <div id="datos-vehiculo">
            <div className="dato" id="input-marca">
              <p>Marca</p>
              <p>{dataOperacion?.marca}</p>
            </div>
            <div className="dato" id="input-modelo">
              <p>Modelo</p>
              <p>{dataOperacion?.modelo}</p>
            </div>
            <div className="dato" id="input-version">
              <p>Versión</p>
              <p>{dataOperacion?.version}</p>
            </div>
            <div className="dato" id="input-ano">
              <p>Año</p>
              <p>{dataOperacion?.ano}</p>
            </div>
          </div>
        </div>
        {/* Datos Personales */}
        <div className="datos-personales-container">
          <p>
            <span>
              <MdPersonOutline />
            </span>
            Datos personales
          </p>
        </div>
        {/* Nombre */}
        <div className="input-container" id="input-nombre">
          <input
            name="nombre"
            type="text"
            id="inputNombre"
            value={userInfo.nombre}
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            className={formErrors.nombre == null ? "" : "input-error"}
          ></input>
          <label
            className={
              userInfo.nombre == null || userInfo.nombre == ""
                ? "labelDown"
                : "labelTop"
            }
            htmlFor="inputNombre"
          >
            Nombre
          </label>
          {formErrors.nombre && <span>{formErrors.nombre}</span>}
        </div>
        {/* Apellido */}
        <div className="input-container" id="input-apellido">
          <input
            name="apellido"
            type="text"
            id="inputApellido"
            value={userInfo.apellido}
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            className={formErrors.apellido == null ? "" : "input-error"}
          ></input>
          <label
            className={
              userInfo.apellido == null || userInfo.apellido == ""
                ? "labelDown"
                : "labelTop"
            }
            htmlFor="inputApellido"
          >
            Apellido
          </label>
          {formErrors.apellido && <span>{formErrors.apellido}</span>}
        </div>
        {/* CUIT / DNI */}
        <div className="input-container" id="input-cuit-dni">
          <input
            name="cuitDNI"
            type="number"
            id="inputCUITDNI"
            value={userInfo.cuitDNI}
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            className={formErrors.cuitDNI == null ? "" : "input-error"}
          ></input>
          <label
            className={
              userInfo.cuitDNI == null || userInfo.cuitDNI == ""
                ? "labelDown"
                : "labelTop"
            }
            htmlFor="inputCUITDNI"
          >
            CUIT o DNI
          </label>
          {formErrors.cuitDNI && <span>{formErrors.cuitDNI}</span>}
        </div>
        {/* Codigo Postal */}
        <div className="input-container" id="input-codigo-postal">
          <input
            // placeholder="Cod. Postal"
            name="codigo_postal"
            type="number"
            id="inputCodigoPostal"
            value={dataOperacion?.sw_operacione.codigo_postal}
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            className={formErrors.codigo_postal == null ? "" : "input-error"}
          ></input>
          <label
            className={
              userInfo.codigo_postal == null || userInfo.codigo_postal == ""
                ? "labelTop"
                : "labelTop"
            }
            htmlFor="inputCodigoPostal"
          >
            Cód. Postal
          </label>
          {formErrors.codigo_postal && <span>{formErrors.codigo_postal}</span>}
        </div>
        {/* Email */}
        <div className="input-container" id="input-email">
          <input
            name="email"
            type="email"
            id="inputEmail"
            value={userInfo.email}
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            className={formErrors.email == null ? "" : "input-error"}
          ></input>
          <label
            className={
              userInfo.email == null || userInfo.email == ""
                ? "labelDown"
                : "labelTop"
            }
            htmlFor="inputEmail"
          >
            Email
          </label>
          {formErrors.email && <span>{formErrors.email}</span>}
        </div>
        {/* Cod. Área */}
        <div className="input-container" id="input-codigo-area">
          <input
            // placeholder="Cod. Area"
            name="codigo_area"
            type="number"
            id="inputCodigoArea"
            value={userInfo.codigo_area}
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            className={formErrors.codigo_area == null ? "" : "input-error"}
          ></input>
          <label
            className={
              userInfo.codigo_area == null || userInfo.codigo_area == ""
                ? "labelDown"
                : "labelTop"
            }
            htmlFor="inputCodigoArea"
          >
            Cód. Área
          </label>
          {formErrors.codigo_area && <span>{formErrors.codigo_area}</span>}
        </div>
        {/* Teléfono */}
        <div className="input-container" id="input-telefono">
          <input
            name="telefono"
            type="numero"
            id="inputTelefono"
            value={userInfo.telefono}
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            className={formErrors.telefono == null ? "" : "input-error"}
          ></input>
          <label
            className={
              userInfo.telefono == null || userInfo.telefono == ""
                ? "labelDown"
                : "labelTop"
            }
            htmlFor="inputTelefono"
          >
            Teléfono
          </label>
          {formErrors.telefono && <span>{formErrors.telefono}</span>}
        </div>
        {/* Datos de Pago Container */}
        <div className="datos-pago-container">
          <p>
            <span>
              <MdCreditCard></MdCreditCard>
            </span>
            Datos de pago
          </p>
        </div>
        {/* Select Forma de Pago */}
        <div className="select-container" id="select-forma-de-pago">
          <select
            id="selectFormaDePago"
            name="formaDePago"
            onChange={handleInputChange}
            onFocus={handleInputChange}
            onBlur={handleInputChange}
            value={userInfo.formaDePago}
            className={formErrors.formaDePago == null ? "" : "input-error"}
          >
            {/* Opción por predeterminado */}
            <option selected disabled></option>
            <option value="Tarjeta de credito">Tarjeta de crédito</option>
            <option value="Transferencia bancaria">
              Transferencia bancaria
            </option>
          </select>
          <div className="flecha-select-container">
            <i></i>
          </div>
          {formErrors.formaDePago && <span>{formErrors.formaDePago}</span>}
        </div>
        {/* Pago Transferencia Bancaria */}
        {userInfo.formaDePago == "Transferencia bancaria" && (
          <div className="input-container" id="input-cbu">
            <input
              type="number"
              id="inputCBU"
              name="cbu"
              onChange={handleInputChange}
              onFocus={handleInputChange}
              onBlur={handleInputChange}
              value={userInfo.cbu}
              className={formErrors.cbu == null ? "" : "input-error"}
            ></input>
            <label
              className={
                userInfo.cbu == null || userInfo.cbu == ""
                  ? "labelDown"
                  : "labelTop"
              }
              htmlFor="inputCBU"
            >
              CBU
            </label>
            {formErrors.cbu && <span>{formErrors.cbu}</span>}
          </div>
        )}
        {/* Pago Tarjeta Container */}
        {userInfo.formaDePago == "Tarjeta de credito" && (
          <div className="pago-tarjeta-container">
            {/* Tarjeta */}
            <Tarjeta
              numero={userInfo.number}
              titular={userInfo.name}
              vencimiento={userInfo.expiry}
              cvc={userInfo.cvc}
              focused={userInfo.focus}
            />
            <div className="datos-tarjeta">
              {/* Input Número de tarjeta */}
              <div className="input-container" id="input-numero-tarjeta">
                <input
                  type="number"
                  id="inputNumeroTarjeta"
                  name="number"
                  onChange={handleInputChange}
                  onFocus={handleInputChange}
                  onBlur={handleInputChange}
                  value={userInfo.number}
                  className={formErrors.number == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    userInfo.number == null || userInfo.number == ""
                      ? "labelDown"
                      : "labelTop"
                  }
                  htmlFor="inputNumeroTarjeta"
                >
                  Número de tarjeta
                </label>
                {formErrors.number && <span>{formErrors.number}</span>}
              </div>
              {/* Input Bnaco Emisor */}
              <div className="input-container" id="input-banco-emisor">
                <input
                  type="text"
                  id="inputBancoEmisor"
                  name="banco"
                  onChange={handleInputChange}
                  onFocus={handleInputChange}
                  onBlur={handleInputChange}
                  value={userInfo.banco}
                  className={formErrors.banco == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    userInfo.banco == null || userInfo.banco == ""
                      ? "labelDown"
                      : "labelTop"
                  }
                  htmlFor="inputBancoEmisor"
                >
                  Banco emisor
                </label>
                {formErrors.banco && <span>{formErrors.banco}</span>}
              </div>
              {/* Input Vencimiento */}
              <div className="input-container" id="input-vencimiento">
                <input
                  type="month"
                  // De esta manera no deja poner tarjetas vencidas y se updatea solo
                  min={añoActual + "-" + mesActual}
                  max={añoActual + 8 + "-" + mesActual}
                  id="inputVencimiento"
                  name="expiry"
                  onChange={handleInputChange}
                  onFocus={handleInputChange}
                  onBlur={handleInputChange}
                  value={userInfo.expiry}
                  className={formErrors.expiry == null ? "" : "input-error"}
                ></input>
                <label className="labelTop" htmlFor="inputVencimiento">
                  Vencimiento
                </label>
                {formErrors.expiry && <span>{formErrors.expiry}</span>}
              </div>
              {/* Input Titular */}
              <div className="input-container" id="input-titular">
                <input
                  type="text"
                  id="inputTitular"
                  name="name"
                  onChange={handleInputChange}
                  onFocus={handleInputChange}
                  onBlur={handleInputChange}
                  value={userInfo.name}
                  className={formErrors.name == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    userInfo.name == null || userInfo.name == ""
                      ? "labelDown"
                      : "labelTop"
                  }
                  htmlFor="inputTitular"
                >
                  Titular
                </label>
                {formErrors.name && <span>{formErrors.name}</span>}
              </div>
              {/* Input DNI Titular */}
              <div className="input-container" id="input-dni-titular">
                <input
                  type="number"
                  id="inputDNITitular"
                  name="dniTarjeta"
                  onChange={handleInputChange}
                  onFocus={handleInputChange}
                  onBlur={handleInputChange}
                  value={userInfo.dniTarjeta}
                  className={formErrors.dniTarjeta == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    userInfo.dniTarjeta == null || userInfo.dniTarjeta == ""
                      ? "labelDown"
                      : "labelTop"
                  }
                  htmlFor="inputDNITitular"
                >
                  DNI Titular
                </label>
                {formErrors.dniTarjeta && <span>{formErrors.dniTarjeta}</span>}
              </div>
            </div>
          </div>
        )}
        {/* Button Contratá to="header" spy={true} */}
        <div id="boton-contrata-container">
          <button type="button" id="boton-contrata" onClick={checkErrors}>
            Contratar
          </button>
        </div>
      </form>
    </Container>
  );
}

import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Botón Atras Container
  .boton-atras-container {
    width: 100%;
    max-width: 1200px;
    margin: 0px 0px -32px 0px;
    display: flex;
    justify-content: flex-start;
    z-index: 98;
  }

  // Botón Atras
  .boton-atras-container button {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    background-color: var(--blanco);
    border: 0px;
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  .boton-atras-container button span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover
  .boton-atras-container button:hover {
    color: var(--azul60);
  }

  // Botón Atras Hover Icono
  .boton-atras-container button:hover > span {
    color: var(--azul60);
    transform: translate(-10px, 0);
  }

  // Max Width Coberturas Container
  #max-width-coberturas-container {
    width: 100%;
    max-width: 931px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Coberturas Container
  .coberturas-container {
    width: 100%;
    max-width: 931px;
    max-height: 115px;
    margin: 80px 0px 0px 0px;
    padding: 24px 24px 24px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    border: 1px solid var(--grisClaro);
    background-color: var(--blanco);
    border-radius: 16px !important;
    box-shadow: 0px 5px 30px var(--sombraAzul);
    transition: 0.2s all;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 100%;
    max-width: 115px;
    padding: 0px 15px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-right: 1px solid var(--grisClaro);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 88%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Logo HDI
  #logo-hdi {
    max-height: 25px !important;
  }

  // Logo Parana
  #logo-parana {
    max-height: 35px !important;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 10px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--azul60);
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 750px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 100%;
    max-width: 120px;
    font-size: 24px;
    text-align: center;
    color: var(--verde50);

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }

  // X Icono
  #check-no {
    font-size: 22px;
    color: var(--rojo) !important;
  }

  // Checks Container Texto
  .checks-container p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--negro);
  }

  // Precio Container
  .precio-container {
    width: 100%;
    max-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Cuotas
  .precio-container div:nth-child(1) p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--negro);

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio anterior
  #precio-anterior {
    text-decoration: line-through !important;
  }

  .precio-container div:nth-child(2) p {
    font-weight: 600;
    font-size: 13px !important;
    text-align: center;
    color: var(--azul60);
    margin: 6px 3px 0px 3px !important;
  }

  // Contratando
  .precio-container div:nth-child(3) p {
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    color: var(--azul60);
  }

  // Formulario
  form {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  /* Datos Cotización Container,
  Datos Personales Container, 
  Datos de Vehículo Container 
  y Datos de Pago Container */
  .datos-cotizacion-container,
  .datos-personales-container,
  .datos-vehiculo-container,
  .datos-pago-container {
    width: 100%;
    margin: 80px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Datos Cotización Container
  // Datos Vehículo Container
  .datos-cotizacion-container,
  .datos-vehiculo-container {
    width: auto;
    display: block;
  }

  // Datos Cotización Container
  .datos-cotizacion-container {
    margin: 80px 40px 0px 0px;
    padding: 0px 20px 0px 0px;
    border-right: 1px solid var(--grisClaro);
  }

  // Datos Cotización
  #datos-cotizacion,
  #datos-vehiculo {
    width: auto;
    display: flex;
  }

  /* Datos Cotización Container Text,
  Datos Personales Container Text, 
  Datos de Vehículo Container Text
  y Datos de Pago Container Text */
  .datos-cotizacion-container p,
  .datos-personales-container p,
  .datos-vehiculo-container p,
  .datos-pago-container p {
    width: auto;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-weight: 600;
    font-style: normal;
  }

  /* Datos Cotización Container Span,
  Datos Personales Container Span, 
  Datos de Vehículo Container Span
  y Datos de Pago Container Span */
  .datos-cotizacion-container span,
  .datos-personales-container span,
  .datos-vehiculo-container span,
  .datos-pago-container span {
    margin: 0px 7px 0px 0px;
    font-size: 24px !important;
    color: var(--azul60);
  }

  // Dato
  .dato {
    width: fit-content;
    margin: 24px 32px 0px 0px;
  }

  // Dato Titulo
  .dato p {
    font-size: 13px;
    font-weight: 400;
    color: var(--negro);
  }

  // Dato Texto
  .dato p:nth-child(2) {
    margin: 7px 0px 0px 0px;
    font-size: 15px;
    font-weight: 600;
  }

  // Input Container y Select Container
  .input-container,
  .select-container {
    width: 283.5px;
    margin: 30px 22px 0px 0px;
    position: relative;
  }

  // Select Container
  .select-container {
    height: 35px;
    margin: 30px 0px 0px 0px !important;
  }

  // Input, Select and Label
  .input-container input,
  .input-container label,
  .select-container select {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
  }

  // Select Label
  .select-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  // Inputs and Select
  .input-container input,
  .select-container select {
    width: 100%;
    height: 35px;
    padding: 0px 16px 0px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    color: var(--gris);
    border: 1px solid var(--gris);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // Select
  .select-container select {
    cursor: pointer;
    height: 100%;
    z-index: 60;
  }

  // Input Error
  .input-error {
    border: 1px solid red !important;
  }

  // Input Error Text
  .input-container span,
  .select-container span {
    font-size: 12px !important;
    color: red;
  }

  // Input Código Postal
  #input-codigo-postal {
    margin: 30px 0px 0px 0px;
  }

  // Input Código Área
  #input-codigo-area {
    width: 90px;
  }

  // Input Teléfono y Input Vencimiento Tarjeta
  #input-telefono,
  #input-vencimiento {
    width: 171.5px;
    margin: 30px 0px 0px 0px;
  }

  #input-nombre input,
  #input-apellido input,
  #input-banco-emisor input,
  #input-titular input {
    text-transform: capitalize !important;
  }

  // Label
  .input-container label,
  .select-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 16px;
    line-height: 1;
    font-size: 0.75em;
    font-weight: 400;
    color: var(--gris);
    cursor: text;
    z-index: 60;
  }

  // Input and Select On Focus Label
  .input-container input:focus + label,
  .select-container select:focus + label {
    top: -0.6em;
    background-color: var(--blanco);
    padding: 0px 6px 0px 8px;
    margin: 0px 0px 0px 12px;
    font-weight: 500;
    font-size: 0.7em;
    color: var(--azul60) !important;
    z-index: 59 !important;
  }

  // Inputs and Select Focus
  .input-container input:focus,
  .select-container select:focus {
    border: 1px solid var(--azul60);
    outline: none;
    z-index: 59 !important;
  }

  // Label Down
  .labelDown {
    z-index: 57 !important;
  }

  // Label Top
  .labelTop {
    top: -0.6em !important;
    background-color: var(--blanco);
    padding: 0px 6px 0px 8px;
    margin: 0px 0px 0px 12px !important;
    font-weight: 500 !important;
    font-size: 0.7em !important;
    color: var(--gris) !important;
    z-index: 59 !important;
  }

  // Input Type Number
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  // Select Flecha Container
  .flecha-select-container {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    z-index: 59;
    transition: 0.2s all;
  }

  // Select Arrow
  .select-container i {
    width: 7px;
    height: 7px;
    margin: -4px 0px 0px 0px;
    display: block;
    border-left: 3px solid var(--gris);
    border-bottom: 3px solid var(--gris);
    transform: rotate(-45deg);
    border-radius: 10%;
    transition: 0.3s all;
  }

  .select-container select:focus ~ .flecha-select-container i {
    margin: 3px 0px 0px 0px !important;
    transform: rotate(135deg) !important;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: var(--negro);
  }

  // Select Forma de pago
  #select-forma-de-pago {
    margin: 30px 22px 0px 0px !important;
  }

  // Pago Tarjeta Container
  .pago-tarjeta-container {
    width: 100%;
    margin: 30px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Datos Tarjeta
  .datos-tarjeta {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Botón Contratá Container
  #boton-contrata-container {
    width: 100%;
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
  }

  // Botón Contratá
  #boton-contrata {
    width: auto;
    margin: 0px 0px 0px 0px;
    padding: 8px 24px 8px 24px;
    background-color: var(--azul60);
    color: var(--blanco);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Botón Contratá Hover
  #boton-contrata:hover {
    background-color: var(--azul20);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 1000px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 1000px;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 881px;
    }

    // Formulario
    form {
      max-width: 1000px;
    }

    // Input Container y Select Container
    .input-container,
    .select-container {
      width: 238.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Telefono
    #input-telefono {
      width: 134.5px;
    }

    // Select Forma de Pago
    #select-forma-de-pago {
      margin: 30px 15px 0px 0px !important;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 680px;
      margin: 40px 0px 0px 0px;
    }

    #max-width-coberturas-container {
      max-width: 680px;
      justify-content: flex-start;
    }

    // Coberturas Container
    .coberturas-container {
      margin: 40px 0px 0px 0px;
      max-width: 251px;
    }

    // Checks Container
    .checks-container {
      width: 120px;
    }

    // Checks Container Span
    .checks-container span {
      display: none;
    }

    /* Datos Cotización Container,
  Datos Personales Container, 
  Datos de Vehículo Container 
  y Datos de Pago Container */
    .datos-cotizacion-container,
    .datos-personales-container,
    .datos-vehiculo-container,
    .datos-pago-container {
      margin: 48px 0px 0px 0px;
    }

    // Datos Cotización Container
    .datos-cotizacion-container {
      margin: 48px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      border-right: 0px;
    }

    // Formulario
    form {
      max-width: 680px;
    }

    // Input Container, Select Container, Input Código Area, Input Telefono y Input Vencimiento
    .input-container,
    .select-container,
    #input-codigo-area,
    #input-telefono,
    #input-vencimiento {
      width: 216.5px;
      max-width: 216.5px;
    }

    // Input Código Postal
    #input-codigo-postal {
      margin: 30px 15px 0px 0px;
    }

    // Input CUIT DNI, Input Localidad y Input Telefono
    #input-cuit-dni,
    #input-localidad,
    #input-telefono,
    #input-codigo-area {
      margin: 30px 0px 0px 0px;
    }

    // Pago Tarjeta Container
    .pago-tarjeta-container {
      flex-wrap: wrap;
    }

    // Datos Tarjeta
    .datos-tarjeta {
      width: 100%;
    }

    #texto-laboral {
      width: 100%;
      margin: 0px 0px 10px 0px;
      display: block;
    }

    #pregunta-laboral {
      margin: 0px 0px 0px 0px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 480px;
    }

    // Formulario
    form {
      max-width: 480px;
    }

    // Datos Vehiculo Container
    .datos-vehiculo-container {
      width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni,
    #input-codigo-area,
    #input-vencimiento,
    #input-telefono {
      width: 232.5px;
      max-width: 232.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Apellido, Input Código Postal, Input Código Área, Input Banco Emisor, Input Titular, Input CBU
    #input-apellido,
    #input-codigo-postal,
    #input-codigo-area,
    #input-banco-emisor,
    #input-titular,
    #input-cbu {
      margin: 30px 0px 0px 0px;
    }

    // Cobertura Laboral
    #cobertura-laboral {
      max-width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 350px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 350px;
    }

    // Formulario
    form {
      max-width: 350px;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni,
    #input-codigo-area,
    #input-vencimiento,
    #input-telefono {
      width: 167.5px;
      max-width: 167.5px;
    }

    // Cobertura Laboral
    #cobertura-laboral {
      max-width: 350px;
    }

    // Pregunta Laboral
    #pregunta-laboral {
      width: 100%;
      margin: 0px 0px 10px 0px;
      display: block;
    }

    // Cobertura Laboral Botón
    #cobertura-laboral a {
      margin: 0px 0px 0px 0px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 85%;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 0px 0px 0px;
      border-right: 0px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 115px;
      margin: 50px calc(85% - 115px) 0px 0px;
    }

    // Formulario
    form {
      max-width: 85%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni,
    #input-codigo-area,
    #input-vencimiento,
    #input-telefono {
      width: 47.4%;
      max-width: 47.4%;
    }

    // Cobertura Laboral
    #cobertura-laboral {
      max-width: 85%;
      line-height: 20px;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni,
    #input-codigo-area,
    #input-vencimiento,
    #input-telefono {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }
  }
`;

export default Container;

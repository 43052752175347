import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";
import { Link } from "react-scroll";
import "material-symbols";
class Plan extends React.Component {
  render() {
    // Next Step
    const Continue = async (e) => {
      e.preventDefault();

      const plan = {
        seguro: this.props.seguro,
        logo: this.props.logo,
        plan: this.props.plan,
        resCivil: this.props.resCivil,
        b1CobTotal: this.props.b1CobTotal,
        tercComp: this.props.tercComp,
        tercCompFull: this.props.tercCompFull,
        todoRiesgo: this.props.todoRiesgo,
        cuotas: this.props.cuotas,
        precioAnterior: this.props.precioAnterior,
        oferta: this.props.oferta,
        precio: this.props.precio,
        contratando: this.props.contratando,
        tienePrecio: this.props.tienePrecio,
      };
      this.props.planSelected(plan);
      this.props.nextStep(this.props.seguro, this.props.tienePrecio);
    };

    return (
      <Container>
        {/* Dropdown */}
        <button
          className={
            this.props.isActive ? "dropdown-abierto" : "dropdown-cerrado"
          }
          onClick={this.props.clickFunction}
        >
          {/* Logo and Plan Container */}
          <div className="logo-plan-container">
            <img
              alt="Logo Seguro"
              src={this.props.logo}
              id={
                this.props.seguro == "HDI Seguros"
                  ? "logo-hdi"
                  : this.props.seguro == "Parana Seguros" && "logo-parana"
              }
            ></img>
            <p>{this.props.plan}</p>
          </div>
          {/* Checks Plan */}
          <section className="checks-container">
            {/* Check Responsabilidad Civil */}
            <span>
              {this.props.resCivil == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Check Robo Total */}
            <span>
              {this.props.b1CobTotal == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Check Robo Parcial */}
            <span>
              {this.props.tercComp == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Check Destruccion Total */}
            <span>
              {this.props.tercCompFull == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Check Asistencia */}
            <span>
              {this.props.todoRiesgo == "si" ? (
                <span class="material-symbols-outlined">done</span>
              ) : (
                <span id="check-no" class="material-symbols-outlined">
                  close
                </span>
              )}
            </span>
            {/* Precios Container */}
            <div className="precio-container">
              {/* Cuotas Container */}
              <div>
                <p>{this.props.precio !== "---" ? this.props.cuotas : null}</p>
              </div>
              {/* Precio */}
              <div>
                <p>
                  {typeof this.props.precio === "number"
                    ? `$${this.props.precio}`
                    : this.props.precio}
                </p>
              </div>
            </div>
          </section>
          {/* Botones Container */}
          <div className="botones-container">
            {/* Botón Contrata */}
            <Link id="contrata-boton" onClick={Continue} to="header" spy={true}>
              {this.props.seguro === "Federación Patronal Seguros" ||
              this.props.seguro === "Experta Seguros" ||
              this.props.seguro === "Orbis Seguros" ||
              !this.props.tienePrecio
                ? "Consultar"
                : "Contratá"}
            </Link>
            {/* Botón Ver mas */}
            <button id="ver-mas-boton" onClick={this.props.clickFunction}>
              {this.props.isActive ? "Ver menos" : "Ver más"}
            </button>
          </div>
        </button>
        {/* Contenido */}
        <Collapse isOpened={this.props.isActive}>
          <div className="children-container">{this.props.children}</div>
        </Collapse>
      </Container>
    );
  }
}

export default Plan;

import * as React from "react";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-scroll";

export default function Pasos({ vehiculo }) {
  return (
    <Container>
      <div id="max-width-container">
        {/* Paso 1 */}
        <Fade delay={100} duration={800} className="paso-container">
          <Link to="cotizador" smooth={true} spy={true} duration={700}>
            <div id="paso1">
              <img src="/images/home/pasos/1.svg" className="flotar"></img>
              <h3>Paso 1</h3>
              <p>
                Solicitá la cotización de tu vehículo desde el formulario en
                nuestra web.
              </p>
            </div>
          </Link>
        </Fade>
        {/* Paso 2 */}
        <Fade delay={100} duration={800} className="paso-container">
          <a
            href="https://api.whatsapp.com/send?phone=541138043972&text=%C2%A1Hola,%20necesito%20un%20asesor%20de%20SeguroAuto!%20Ingresé%20desde%20la%20web"
            target="_blank"
          >
            <div>
              <img src="/images/home/pasos/2.svg" className="flotar"></img>
              <h3>Paso 2</h3>
              <p>
                Un asesor se pondrá en contacto con vos<br></br>(Días hábiles de
                9 a 18hs)
              </p>
            </div>
          </a>
        </Fade>
        <Fade delay={100} duration={800} className="paso-container">
          {/* Paso 3 */}
          <div>
            <img
               src={ `/images/home/pasos/${ vehiculo }.svg` }
              className="flotar"
            ></img>
            <h3>Paso 3</h3>
            <p>
              ¡Ya tenés tu póliza!<br></br>Salí seguro y sin preocupaciones
            </p>
          </div>
        </Fade>
      </div>
    </Container>
  );
}

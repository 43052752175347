import React, { Component } from "react";
import Container from "./styled";
import Pregunta from "./pregunta";
import { Fade } from "react-awesome-reveal";
import { BsWhatsapp } from "react-icons/bs";

export default class Faqs extends Component {
  // Estado que determina si esta abierta o no la question
  state = {
    isActiveUno: false,
    isActiveDos: false,
    isActiveTres: false,
    isActiveCuatro: false,
    isActiveCinco: false,
    isActiveSeis: false,
    isActiveSiete: false,
    isActiveOcho: false,
    isActiveNueve: false,
    isActiveDiez: false,
    isActiveOnce: false,
    isActiveDoce: false,
  };

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de las questions
  handleToggleUno = () => {
    this.setState({
      isActiveUno: !this.state.isActiveUno,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleDos = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: !this.state.isActiveDos,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleTres = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: !this.state.isActiveTres,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleCuatro = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: !this.state.isActiveCuatro,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleCinco = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: !this.state.isActiveCinco,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleSeis = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: !this.state.isActiveSeis,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleSiete = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: !this.state.isActiveSiete,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleOcho = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: !this.state.isActiveOcho,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleNueve = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: !this.state.isActiveNueve,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleDiez = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: !this.state.isActiveDiez,
      isActiveOnce: false,
      isActiveDoce: false,
    });
  };

  handleToggleOnce = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: !this.state.isActiveOnce,
      isActiveDoce: false,
    });
  };

  handleToggleDoce = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: false,
      isActiveSeis: false,
      isActiveSiete: false,
      isActiveOcho: false,
      isActiveNueve: false,
      isActiveDiez: false,
      isActiveOnce: false,
      isActiveDoce: !this.state.isActiveDoce,
    });
  };

  render() {
    return (
      <Container>
        <div id="max-width-container">
          {/* Titulo */}
          <h3>
            <span>Preguntas</span> más frecuentes
          </h3>
          {/* Preguntas Container */}
          <div id="preguntas-container">
            {/* Pregunta 1 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveUno == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cuáles son los requisitos para contratar?"
                clickFunction={this.handleToggleUno}
                isActive={this.state.isActiveUno}
              >
                Los requisitos son: Fotos de los 4 lados del auto, foto del
                parabrisas desde el interior, foto de la rueda de auxilio. Todas
                las fotos deben tener la fecha del día en que fueron tomadas. Si
                es Okm, se puede presentar la constancia de no rodamiento.
              </Pregunta>
            </Fade>
            {/* Pregunta 2 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveDos == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Puedo asegurar si el auto no está a nombre mío?"
                clickFunction={this.handleToggleDos}
                isActive={this.state.isActiveDos}
              >
                Sí, si el auto no está a tu nombre, igual lo podés asegurar.
              </Pregunta>
            </Fade>
            {/* Pregunta 3 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveTres == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cuando inicia la vigencia de la póliza?"
                clickFunction={this.handleToggleTres}
                isActive={this.state.isActiveTres}
              >
                La póliza empieza a rendir desde las 12hs del día siguiente una
                vez contratado.
              </Pregunta>
            </Fade>
            {/* Pregunta 4 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveCuatro == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="Si el uso es remis, ¿se puede asegurar?"
                clickFunction={this.handleToggleCuatro}
                isActive={this.state.isActiveCuatro}
              >
                No, podés seguir o dar de baja el seguro cuando desees.
              </Pregunta>
            </Fade>
            {/* Pregunta 5 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveCinco == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Qué significa el tradicional 'terceros completos'?"
                clickFunction={this.handleToggleCinco}
                isActive={this.state.isActiveCinco}
              >
                Significa que cubre responsabilidad civil, robo e incendio total
                y parcial, y destrucción total.
              </Pregunta>
            </Fade>
            {/* Pregunta 6 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveSeis == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="Terceros Completo, ¿me cubre contra todo?"
                clickFunction={this.handleToggleSeis}
                isActive={this.state.isActiveSeis}
              >
                No, no cubre todo, ya que no incluye todo riesgo.
              </Pregunta>
            </Fade>
            {/* Pregunta 7 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveSiete == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cuál es la cobertura más completa?"
                clickFunction={this.handleToggleSiete}
                isActive={this.state.isActiveSiete}
              >
                Son las coberturas que incluye daños parciales: todo riesgo.
              </Pregunta>
            </Fade>
            {/* Pregunta 8 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveOcho == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="En mi auto tengo techo solar de fábrica, ¿se cubre?"
                clickFunction={this.handleToggleOcho}
                isActive={this.state.isActiveOcho}
              >
                Sí, los seguros cubren techo solar de fábrica.
              </Pregunta>
            </Fade>
            {/* Pregunta 9 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActivenNueve == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="Si el auto tiene GNC, ¿se puede asegurar?"
                clickFunction={this.handleToggleNueve}
                isActive={this.state.isActiveNueve}
              >
                Sí, nos debés informar que el auto tiene GNC, el cual debe estar
                al día para poder asegurarlo.
              </Pregunta>
            </Fade>
            {/* Pregunta 10 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveDiez == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Puede manejar el auto otra persona que no sea el asegurado?"
                clickFunction={this.handleToggleDiez}
                isActive={this.state.isActiveDiez}
              >
                Sí, siempre y cuando tenga registro habilitante y la cédula
                azul.
              </Pregunta>
            </Fade>
            {/* Pregunta 11 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveOnce == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Dónde llamar en caso de siniestro?"
                clickFunction={this.handleToggleOnce}
                isActive={this.state.isActiveOnce}
              >
                Contactate de inmediato con nosotros por whatsapp.{" "}
                <a
                  href="https://api.whatsapp.com/send?phone=541128061469"
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp className="logoMobile" />
                  </span>
                  11 2806 1469
                </a>{" "}
                /{" "}
                <a
                  href="https://api.whatsapp.com/send?phone=541162096951"
                  target="_blank"
                >
                  11 6209 6951
                </a>
                , o mandános un mail a:{" "}
                <a id="mailButton" href="mailto:siniestros@seguroweb.com.ar">
                  siniestros@seguroweb.com.ar
                </a>
                . Estaremos acompañándote en todo el proceso hasta el cobro de
                la indemnización.
              </Pregunta>
            </Fade>
            {/* Pregunta 12 */}
            <Fade
              delay={100}
              duration={600}
              triggerOnce={true}
              className={
                this.state.isActiveDoce == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Me cubre si me roban el estéreo?"
                clickFunction={this.handleToggleDoce}
                isActive={this.state.isActiveDoce}
              >
                No, los estéreos no se cubren en caso de robo.
              </Pregunta>
            </Fade>
          </div>
        </div>
      </Container>
    );
  }
}

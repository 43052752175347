import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Headroom from "react-headroom";
import Header from "../components/header";
import Cotizador from "../components/cotizador";
import Logos from "../components/home/logos";
import Cubrimos from "../components/home/cubrimos";
import Referidos from "../components/home/referidos";
import Canales from "../components/home/canales";
import FAQs from "../components/home/faqs";
import Footer from "../components/footer";
import BotonFlotante from "../components/home/boton-flotante";
import { useSEO } from "../hooks/useSEO";

const Container = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;

  #coti-cober {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export default function HomeContainer() {
  // Estado para vehiculo
  const [vehiculo, setVehiculo] = useState(
    localStorage.getItem("producto-seguroautos") !== null
      ? localStorage.getItem("producto-seguroautos")
      : "auto"
  );
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useSEO({ 
    title: "Seguro Auto - Cotizá tu Seguro de Auto",
    description: "¡Cotizá tu seguro online! Robo total desde 1990, terceros con granizo desde 2001, todo riesgo desde 2014 ¡conocé todas las opciones de cobertura!"
  });

  // Referidos Header
  const [isReferidosHeaderOpen, setReferidosHeaderOpen] = useState(true);
  const toggleReferidosHeader = () => {
    setReferidosHeaderOpen(!isReferidosHeaderOpen);
  };

  const actualizarVehiculo = (e) => {
    setVehiculo(e);
  };

  return (
    <Container>
      {/* Head */}
      <head>
        <title>Seguro Autos</title>
        <meta name="description" content="¡Cotizá tu seguro online! Robo total desde 1990, terceros con granizo desde 2001, todo riesgo desde 2014 ¡conocé todas las opciones de cobertura!" />
      </head>

      {/* Boton Flotante WhatsApp*/}
      <BotonFlotante />

      {/* Header */}
      <Headroom>
        <Header
          vehiculo={vehiculo}
          isReferidosHeaderOpen={isReferidosHeaderOpen}
          toggleReferidosHeader={toggleReferidosHeader}
        ></Header>
      </Headroom>
      <div id="coti-cober">
        {/* Cotizador */}
        <Cotizador
          isReferidosHeaderOpen={isReferidosHeaderOpen}
          subtitle="Asegurá tu vehículo y manejá de la mejor manera"
          actualizarVehiculo={actualizarVehiculo}
        />
        <Cubrimos />
      </div>
      
      {/* Logos */}
      <Logos />
      {/* Referidos */}
      <Referidos />
      {/* Caneles */}
      <Canales />
      
      {/* Faqs */}
      <FAQs></FAQs>
      {/* Footer */}
      <Footer 
        marcas="active"
        vehiculo={ vehiculo }
      ></Footer>
    </Container>
  );
}

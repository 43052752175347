import React, { useState } from "react";
import Container from "./styled";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import Marcas from "./marcas";
import { useNavigate, Link } from "react-router-dom";
import { Logos } from "../header/logos";

export default function Footer({ link, marcas, pagina, vehiculo }) {
  return (
    <Container pagina={pagina}>
      {/* Marcas */}
      <Marcas marcas={marcas}></Marcas>
      {/* Data Nav Container */}
      <div className="data-nav-container">
        {/* Data Container */}
        <div className="data-container">
          {/* Logo */}
          <Link to={link ? link : "/"}>
            <Logos vehiculo={ vehiculo } />
          </Link>
          {/* Social Media Mobile Container */}
          <div className="social-media-mobile-container">
            {/* Botón Facebook */}
            <a href="https://www.facebook.com/SeguroWeb/" target="_blank">
              <FaFacebookF />
            </a>
            {/* Botón Instagram */}
            <a href="https://www.instagram.com/seguroweb/" target="_blank">
              <BsInstagram />
            </a>
            {/* Botón WhatsApp */}
            <a
              href="https://api.whatsapp.com/send?phone=541162255399&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20SeguroAuto!."
              target="_blank"
            >
              <BsWhatsapp />
            </a>
          </div>
          {/* Data */}
          <div className="data">
            <a href="tel:08102202373">0810-220-2373</a>
            <a href="tel:1152632581">(011) 5263-2581</a>
            <p>info@seguroweb.com.ar</p>
            <p>Suipacha 245 1° Piso CABA</p>
          </div>
        </div>
        {/* Navs Container */}
        <div className="navs-container">
          {/* Columna */}
          <div className="navs-container-columna">
            {/* Titulo */}
            <p>Mapa del sitio</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <Link to="/contacto">Contacto</Link>
              </li>
              <li>
                <Link to="/referidos">Referidos</Link>
              </li>
            </nav>
          </div>
          {/* Column Nav Container */}
          <div className="navs-container-columna">
            {/* Titulo */}
            <p>Ayuda</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/baja-de-servicio">Baja de servicio</Link>
              </li>
              <li>
                <Link to="/arrepentimiento-de-compra">
                  Arrepentimiento de compra
                </Link>
              </li>
            </nav>
          </div>
          {/* Column Nav Container */}
          <div className="navs-container-columna">
            {/* Titulo */}
            <p>Legales</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/politicas-y-terminos">Políticas y términos</Link>
              </li>
            </nav>
          </div>
          {/* Column Nav Container */}
          <div className="navs-container-columna">
            {/* Titulo */}
            <p>La empresa</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/sobre-nosotros">Sobre nosotros</Link>
              </li>
              <li>
                <Link to="/forma-parte">Formá parte</Link>
              </li>
            </nav>
          </div>
        </div>
      </div>
      {/* Social Media y Logos Container */}
      <div className="social-media-logos-container">
        {/* Social Media Container */}
        <div className="social-media-container">
          {/* Botón Facebook */}
          <a href="https://www.facebook.com/SeguroWeb/" target="_blank">
            <FaFacebookF />
          </a>
          {/* Botón Instagram */}
          <a href="https://www.instagram.com/seguroweb/" target="_blank">
            <BsInstagram />
          </a>
          {/* Botón WhatsApp */}
          <a
            href="https://api.whatsapp.com/send?phone=541162255399&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20SeguroAuto!."
            target="_blank"
          >
            <BsWhatsapp />
          </a>
        </div>
        {/* Logos Container */}
        <div className="logos-container">
          {/* Logo */}
          <a href="https://gys.com.ar/" target="_blank" id="logo-gys-boton">
            <img
              alt="Logo Gestión y Servicios SRL"
              src="/images/logos/gys.svg"
              id="logo-gys"
            ></img>
          </a>
          {/* Logo */}
          <img
            alt="Logo Lets Encrypt"
            src="/images/logos/letsencrypt.png"
            id="logo-encrypt"
          ></img>
          {/* Logo */}
          <a
            href="https://www.gyssrl.com.ar/webroot/footer/certificadoapt.pdf"
            target="_blank"
            id="logo-SSN"
          >
            <img alt="Logo SSN" src="/images/logos/ssn.png" id="logoSSN"></img>
          </a>
          {/* Logo */}
          <img alt="Logo PDP" src="/images/logos/pdp.png" id="logo-PDP"></img>
          {/* Logo */}
          <a
            href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=vfDql9TuSM09b_FRxAacFg,,"
            target="_F960AFIPInfo"
            id="logo-fiscal"
          >
            <img
              alt="Logo Data Fiscal"
              src="/images/logos/datafiscal.webp"
              id="logoFiscal"
            ></img>
          </a>
        </div>
        <img
          alt="Edificios del isotipo de Gestión y Servicios"
          src="/images/footer/edificios-borde.svg"
          id="edificios"
        ></img>
      </div>
      {/* Texto Copyright */}
      <div id="texto-copyright-container">
        <p>
          © Copyright 2023 SeguroAuto | Todos los derechos reservados |
          SeguroAuto es marca de Gestión y Servicios S.R.L.
        </p>
      </div>
    </Container>
  );
}
